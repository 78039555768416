<template>
	<div>
		<div class="width90" style="text-align: center;">
			
			<div class="blod rowCenter" style="padding-top: 16px;" v-if="custom==1">
				<div style="font-size: 30px;">￥</div>
				<van-field type="number" v-model="customPrice" placeholder="Please enter the amount you want to recharge" />
			</div>
			
			<div class="blod" style="padding-top: 16px;" v-if="custom!=1">
				<span class="text-18">￥</span>
				<span class="text-32">{{price}}</span>
			</div>
			
			<div class="between" style="padding-top: 39px;"><!-- style="padding: 39px 0 53px 0;" -->
				<div>payType</div>
				<div class="rowCenter">
					<img class="img16" src="../assets/images/weixin.png" />
					<div style="padding-left: 5px;">WeChat</div>
				</div>
			</div>
			<div class="" style="width: 100%;margin: 30px 0;">
				<van-button @click="pay" :disabled="loadingBtn" style="width: 90vw;margin: auto;" color="linear-gradient(to right,#03B0F9,#0080FD)" type="primary" round>pay</van-button>
				
			</div>
			<!-- <div class="width90" style="padding-bottom: 32px;" @click="pay">
				
				<van-button round class="width90" color="linear-gradient(to right, #03B0F9, #0080FD)">
					立即支付
				</van-button>
			</div> -->
		</div>

	</div>
</template>

<script>
	import { Toast } from 'vant';
	export default {
		data() {
			return {
				customPrice:null,
				loadingBtn:false
			}
		},
		props: {
			price: {
				type: Number,
				default: 0
			},
			custom:{
				type:Number,
				default:0//0传入价格，1自定义,3次数套餐充值 
			},
			orderId:{
				type:String,
				default:"0"
			}
		},
		methods:{
			pay(){
				this.loadingBtn=true
				var param={}
				if(this.custom==1){
					console.log("自定义支付",this.customPrice)
					param.payMoney=this.customPrice
					param.payType=1
					this.wxPay(param)
				}else if(this.custom==0){
					console.log("支付",this.price)
					param.payType=2
					param.payId=this.orderId
					this.wxPay(param)
				}else if(this.custom==3){
					console.log("支付",this.price)
					param.payType=3
					param.payId=this.orderId
					this.wxPay(param)
				}
			},
			wxPay(param){
				this.$axios.post("/wx/h5Pay/wxH5Pay",param).then(res=>{
					console.log("微信支付",res)
					
					if(res.code==1){
						var that=this
						window.WeixinJSBridge.invoke(
							'getBrandWCPayRequest', {
								"appId": res.data.appId, //公众号名称，由商户传入     
								"timeStamp": res.data.timeStamp, //时间戳，自1970年以来的秒数     
								"nonceStr": res.data.nonceStr, //随机串     
								"package": res.data.package,
								"signType": res.data.signType, //微信签名方式:     
								"paySign": res.data.paySign //微信签名 
							},
						
							function(res) {
								that.loadingBtn=false
								if (res.err_msg == "get_brand_wcpay_request:ok") {
						
									Toast("支付成功");
									that.$emit("paySuccess")
								} // 使用以上方式判断前端返回,微信团队郑重提示：res.err_msg将在用户支付成功后返回    ok，但并不保证它绝对可靠。 
								else{
									Toast("支付失败");
								}
							}
						)
					}else{
						Toast(res.msg)
						this.$emit("payFail")
					}
					
				})
			}
		}
	}
</script>

<style>
</style>
