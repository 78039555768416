<template>
	<div>
		<div style="width: 88px;margin: auto;margin-top: 50px;">
			<img style="width: 88px;margin: auto;" src="../assets/images/login.png" />
		</div>
		
		<div class="width90" style="margin: 30px auto;">
			
			<div>
				<van-field v-model="phone" label="phone" placeholder="please enter phone" />
				<van-field v-model="password" label="password" type="password" placeholder="please enter password" />
			</div>
			
			
			<van-button @click="login" style="width: 90vw;margin-top: 20px;" :color="colorBtn" type="primary" round>login</van-button>
			
			<div class="betweenCenter margin-top-10" @click="toLink">
				<div></div>
				<div class="text-12">Don't have an account yet? Go to register</div>
			</div>
			
			<div style="padding-top: 20px;" class="rowCenter">
				<van-checkbox v-model="checkAgreement" icon-size="14px">
					
				</van-checkbox>
				<span class="text-14 margin-left-8">agree<span class="blue" @click.stop="toAgreement">User Agreement</span></span>
			</div>
		</div>
	</div>
</template>

<script>
	import BaseView from "@/views/BaseView.vue"
	import {
		Toast
	} from 'vant';
	export default {
		extends: BaseView,
		data() {
			return {
				title: "login",
				backcolor: "#FFFFFF",
				checkAgreement: false,
				showMemberId: false,
				phone:"",
				password:"",
				code: "",
				// memberId:"10000001"
			}
		},
		created() {

			this.checkAgreement = localStorage.getItem("checkAgreement")


		},
		methods: {
			clickShow() {
				this.showMemberId = true

			},
			login() {
				if (!this.checkAgreement) {

					Toast("Please agree to the user agreement！")
					return;
				}

				if (!this.phone) {
				
					Toast("please enter phone！")
					return;
				}
				if (!this.password) {
				
					Toast("please enter password！")
					return;
				}
				localStorage.setItem("checkAgreement", this.checkAgreement)

				//授权登录
				this.$axios.post("/member/base/recyclerUserLogin", {
					phone: this.phone,
					password:this.password
				}).then(res => {
					console.log("登录", res)
					if (res.code == 1) {
						localStorage.setItem('token', res.data);
						// this.$router.go(-1)
						this.$router.push({
							path: "/"
						})
					} else {
						Toast(res.msg)
					}
				})
			},
			toLink() {
				this.$router.push({
					path: "/register"
				})
			}
		}
	}
</script>

<style>
</style>