<template>
	<div>
		<!-- 用户信息 -->
		<div class="rowCenter" style="padding: 14px 0;">
			<div class="headerImg" style="margin-left: 5vw;align-items: center;">
				<img class="headerImg" :src="userInfo.avatarUrl" />
			</div>
			<div class="userInfo" style="margin-left: 12px;">
				<div class="text-16 blod">{{userInfo.nickName}}</div>
				<div class="text-12">ID：{{userInfo.id}}</div>
				<div class="text-12">{{userInfo.vipState==1?"已开通":"尚未开通"}}</div>
			</div>
		</div>

		<div style="height: 10px;background: #F5F5F5;"></div>

		<!-- 会员套餐 -->
		<van-empty v-if="setMealList.length<=0" class="custom-image" :image="emptyImg" description="还没有套餐~" />
		<div v-if="setMealList.length>0">
			<div class="text-14 blod" style="padding: 17px 5vw ;">会员套餐</div>
			<div ref="wrapper" style="width: 100vw;overflow: hidden;">
				<!-- /* 这里是父盒子*/ -->
				<div class="cont" ref="cont" style="padding-left: 5vw;">
					<!-- /* 这里是子盒子，即滚动区域*/ -->
					<div class="cont-item" v-for="(item,index) in setMealList" :key="index" @click="onMeal(index)" :class="[clickMeal==index?'meal-act':'meal']">
						<div class="text-12" style="padding-top: 14px;">检测次数</div>
						<div class="text-14 blod" style="padding: 10px 0;">
							{{item.setValue}}次
						</div>
						<div class="blue" style="padding-bottom: 15px;">
							<span class="text-16">￥</span>
							<span class="text-20">{{item.setPrice}}</span>

						</div>
					</div>
				</div>
			</div>

			<!-- 套餐描述 -->
			<div>
				<div class="text-12" style="padding: 14px 5vw ;">套餐描述</div>

				<div class="text-12" style="padding: 10px 10vw ;">

					{{setMealList[clickMeal].setDescribe}}
				</div>
			</div>
		</div>
		
		<div class="width90" style="margin-top: 90px;" @click="buy">
			<van-button round class="width90" :color="colorBtn" :disabled="loadingBtn">
			  立即购买
			</van-button>
		</div>
		
		<!-- 选择支付 -->
		<van-action-sheet v-model="showPay" title="选择支付">
			<wxpayment :price="mealInfo.setPrice" :custom="3" :orderId="orderId" @paySuccess="paySuccess"></wxpayment>
		</van-action-sheet>
	</div>
</template>

<script>
	import BaseView from "@/views/BaseView.vue"
	import BScroll from "better-scroll";
	import wxpayment from "@/components/wxpayment.vue"
	import {Toast} from 'vant';
	export default {
		extends: BaseView,
		components: {
			wxpayment
		},
		data() {
			return {
				title: "充值套餐",
				backcolor: "#FFFFFF",
				clickMeal: 0,
				showPay:false,
				mealInfo:{},
				userInfo:{},
				param:{
					page:1
				},
				setMealList: [],
				orderId:"0"
			}
		},
		created() {
			this.getSetMeal()
			this.getUserInfo()
		},
		mounted() {
			// this.$nextTick(() => {
			// 	let timer = setTimeout(() => { // 其实我也不想写这个定时器的，这相当于又嵌套了一层$nextTick，但是不这么写会失败
			// 		if (timer) {
			// 			clearTimeout(timer)
			// 			this.verScroll()
			// 		}
			// 	}, 0)
			// })
		},
		methods: {
			//查询用户信息
			getUserInfo(){
				this.$axios.get("member/base/memberInfo").then(res=>{
					console.log("用户信息",res)
					this.userInfo=res.data
					if(this.userInfo.avatarId){
						this.userInfo.avatarUrl=this.imageUrl+this.userInfo.avatarUrl
					}
				})
			},
			//查询套餐
			getSetMeal(){
				this.$axios.get("meal/base/numberList",{params:this.param}).then(res=>{
					console.log("套餐",res)
					if(res.code==1){
						this.setMealList=res.data
						this.$nextTick(() => {
							let timer = setTimeout(() => { // 其实我也不想写这个定时器的，这相当于又嵌套了一层$nextTick，但是不这么写会失败
								if (timer) {
									clearTimeout(timer)
									this.verScroll()
								}
							}, 0)
						})
					}
				})
			},
			verScroll() {
				let width = this.setMealList.length * 105 // 动态计算出滚动区域的大小，
				this.$refs.cont.style.width = width + 'px' // 修改滚动区域的宽度
				this.$nextTick(() => {
					if (!this.scroll) {
						this.scroll = new BScroll(this.$refs.wrapper, {
							startX: 0, 
							click: true,
							scrollX: true,
							scrollY: false,
							eventPassthrough: 'vertical'
						})
					} else {
						this.scroll.refresh() //如果dom结构发生改变调用该方法
					}
				})
			},
			onMeal(index) {
				console.log("选择套餐",index)
				this.clickMeal = index
			},
			buy(){
				if(this.userInfo.memberType!=1){
					Toast("您不是商家，没有充值权限！")
					return;
				}
				this.loadingBtn=true
				this.mealInfo=this.setMealList[this.clickMeal]
				this.$axios.post("/meal/base/rechargeOrder",{
					rechargeId:this.mealInfo.id
				}).then(res=>{
					console.log("余额",res)
					this.loadingBtn=false
					this.orderId=res.data
					this.showPay=true
				})
			},
			paySuccess(){
				this.showPay=false
				this.getUserInfo()
			}
		}
	}
</script>

<style>
	.headerImg {
		width: 60px;
		height: 60px;
		border-radius: 60px;
		background: #BEC0C4;
	}

	.cont {
		list-style: none;
		/* overflow-x: scroll;  */
		white-space: nowrap;
		font-size: 12px;
		text-align: center;
		padding-right: .24rem;


	}

	.cont-item {
		position: relative;
		display: inline-block;
		padding: .06rem 0 .2rem;
		width: 2rem;
		margin: 0 .1rem;
	}

	.meal {
		width: 100px;
		border: #BEC0C4 1px solid;
		border-radius: 5px;
	}

	.meal-act {
		width: 100px;
		border: #0080FD 1px solid;
		background: #E5F2FF;
		border-radius: 5px;
	}
</style>
