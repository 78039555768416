<template>
	<div>
		<van-form @submit="onSubmit">
			<div class="meansForm">
				<div v-if="userInfo.memberPhone">
					<van-field clearable readonly v-model="userInfo.memberName" name="memberName" label="name"
						placeholder="Please enter your real name" />
				</div>
				<div v-if="!userInfo.memberPhone">
					<van-field clearable v-model="userInfo.memberName" name="memberName" label="name"
						placeholder="Please enter your real name" :rules="[{ required: true, message: 'Please enter your real name' }]"/>
				</div>


				<van-field clearable v-model="userInfo.nickName" name="nickName" label="nickName" placeholder="Please enter a nickname" />

				<div v-if="userInfo.memberPhone">
					<van-field readonly name="tel" :value="userInfo.memberPhone" label="phone" />
				</div>

				<div v-if="!userInfo.memberPhone">
					<van-field v-model="tel" clearable name="tel" label="phone" placeholder="Please enter the contact phone number"
						:rules="[{ pattern, message: 'Please enter the contact phone number' }]" />

					<van-field v-model="sms" name="smsCode" center clearable label="code" placeholder="Please enter the verification code"
						:rules="[{ required: true, message: 'Please enter the verification code' }]">
						<template #button>
							<div class="codeBtn" @click="sendCode">{{btnText}}</div>
						</template>
					</van-field>
				</div>

				<div style="padding: 10px 4vw;">
					<div class="text-14">upload avatar</div>

					<van-uploader v-if="!avatarUrl" style="margin-top: 10px;" :after-read="afterRead"
						:before-delete="deleteImg" v-model="fileList" :max-count="1">

					</van-uploader>
					
					<div v-if="avatarUrl" style="position: relative;margin-top: 10px;width: 80px;" >
						<van-icon name="cross" style="position: absolute;top: 0;right: 0;z-index: 222;background: rgba(0,0,0,0.15);" @click="deleteImg"/>
						<van-image  width="80px" height="80px"  fit="cover" :src="avatarUrl">
						</van-image>
					</div>
					
				</div>


			</div>

			<div class="width90" style="margin: auto;margin-top: 80px;">
				<van-button :disabled="loadingBtn"  class="width90" round block :color="colorBtn" native-type="submit">submit
				</van-button>
			</div>
		</van-form>
	</div>
</template>

<script>
	import BaseView from "@/views/BaseView.vue"
	import {
		Toast
	} from 'vant';
	export default {
		extends: BaseView,
		data() {
			return {
				title: "modify data",//修改资料
				nickName: "",
				avatarJson: "",
				fileList: [],
				param: {},
				tel: "",
				sms: "",
				btnText: "send ",//发送验证码
				seconds: 60,
				disable: false,
				userInfo: {},
				avatarUrl:''
			}
		},
		created() {
			this.getUserInfo()
		},
		methods: {
			//查询用户信息
			getUserInfo() {
				this.$axios.get("member/base/memberInfo").then(res => {
					console.log("用户信息", res)
					this.userInfo = res.data
					if (this.userInfo.avatarUrl) {
						this.avatarUrl=this.userInfo.avatarUrl
						if(this.userInfo.avatarId){
							this.avatarUrl=this.imageUrl+this.userInfo.avatarUrl
						}
						this.userInfo.avatar = this.userInfo.avatarUrl
						this.param.avatarJson = this.userInfo.avatarId
						this.fileList.push({
							url: this.userInfo.avatar
						})
					}

				})
			},
			//上传图片
			afterRead(file) {
				console.log("上传图片", file)
				file.status = 'uploading'
				file.message = 'uploading...'
				var datas = new FormData()
				datas.append("file", file.file)
				this.$axios.post("img/base/imgUpLoad", datas).then(res => {
					console.log("图片上传", res)
					if (res.code == 1) {
						file.status = 'done'
						this.param.avatarJson = res.data.id
						this.avatarUrl=res.data.url
					} else {
						file.status = 'failed'
						file.message = "failed"
					}
				}).catch(err => {
					// console.log("错误",err)
					file.status = 'failed'
					file.message = "failed"
				})
			},
			//删除图片
			deleteImg() {
				delete this.param.avatarJson
				this.avatarUrl=""
				this.fileList = []
			},
			//发送验证码
			sendCode() {
				if (this.disable) {
					console.log("禁止点击")
					return;
				}
				if (!this.pattern.test(this.tel)) {
					Toast("Please enter the contact phone number")
					return;
				}
				this.seconds = 60
				this.btnText = this.seconds + "s"
				let param = {
					phone: this.tel,
					type: 0
				}
				// console.log("验证码", param)

				this.$axios.post("smsCode/base/sendMessageCode", param).then(res => {
					// console.log("发送验证码", res)
					if (res.code == 1) {
						Toast("success！")
						if (this.seconds > 0) {
							let timer = setInterval(() => {
								if (this.seconds > 0) {
									this.seconds--
									this.btnText = this.seconds + "s"
									this.disable = true
								} else {
									clearInterval(timer)
									this.btnText = "send"
									this.disable = false
								}

							}, 1000)
						}

					} else {
						Toast("fail！")
						this.btnText = "send"
						this.disable = false
					}
				})
			},
			onSubmit(value) {
				this.loadingBtn=true
				if(this.param.avatarJson){
					value.avatarId = this.param.avatarJson
				}
				if(this.userInfo.memberPhone){
					delete value.tel
				}
				console.log("提交信息", value)
				this.$axios.post("member/base/perfectMemberInfo", value).then(res => {
					console.log("修改资料", res)
					this.loadingBtn=false
					if (res.code == 1) {
						Toast("success")
						setTimeout(()=>{
							this.$router.go(-1)
						},1000)
					} else {
						Toast(res.msg)
					}

				}).catch(err => {
					Toast("fail！")
				})
			}
		}
	}
</script>

<style>
	.meansForm .van-cell {
		border-radius: 10px;
	}

	.meansForm {
		width: 90vw;
		background: #FFFFFF;
		border-radius: 10px;
		margin: auto;
		margin-top: 15px;
	}
</style>
