<template>
    <div class="load-box">
        <van-image class="load-box__img" :src="require('@/assets/images/loading.gif')" />
    </div>
</template>

<script>
/**
 * 全屏过渡动画
 */
export default {
    name: 'LoadingBig',
    props: {
        
    },
    data() {
        return {

        };
    }
}
</script>

<style scoped>
.load-box {
    display: flex;
    height: 100vh;
    justify-content: center;
	align-items: center;
}

.load-box >>> .load-box__img {
    width: 50px;
    height: 50px;
}
</style>
