<template>
	<div>
		<van-tabbar route active-color="#0080FD" placeholder >
			<van-tabbar-item replace v-for="(item,index) in tabConten" :key="index" :to="item.url">
				<span>{{item.name}}</span>
				<template #icon="props">
					<img :src="props.active?item.imgAct:item.images" />
				</template>
			</van-tabbar-item>
		</van-tabbar>
	</div>
	
</template>

<script>
	export default{
		data(){
			return{
				active:0,
				tabConten:[
					{
						// name:"首页",
						name:"home",
						images:require("@/assets/images/tabbar/tab1.png"),
						imgAct:require("@/assets/images/tabbar/tab1-act.png"),
						url:"/"
					},
					{
						// name:"证书查询",
						name:"query",
						images:require("@/assets/images/tabbar/tab2.png"),
						imgAct:require("@/assets/images/tabbar/tab2-act.png"),
						url:"/certificateQuery"
					},
					{
						// name:"我的证书",
						name:"certificate",
						images:require("@/assets/images/tabbar/tab3.png"),
						imgAct:require("@/assets/images/tabbar/tab3-act.png"),
						url:"/myCertificate"
					},
					{
						// name:"我的",
						name:"personal",
						images:require("@/assets/images/tabbar/tab4.png"),
						imgAct:require("@/assets/images/tabbar/tab4-act.png"),
						url:"/prsonal"
					}
				]
			}
		}
	}
</script>

<style>
</style>
