<template>
	<div>
		<div v-if="loadingBig">
			<loadingBig></loadingBig>
		</div>
		<div v-else>
			<div class="orderDetailTop">
				<div class="flex-row" style="width: 80px;margin: auto;padding-top: 40px;">
					<!-- <img :src="orederDetail1" /> -->
					<img style="width: 22px;height: 22px;" :src="navList[orderDetailInfo.state].img" />
					<div style="margin: auto;">{{navList[orderDetailInfo.state].name}}</div>
				</div>
				
				
			
			
			</div>
			<div class="width82 backgroundWhite radius5" style="margin-top: -60px;" v-if="orderDetailInfo.printMode==1">
				<!-- 邮寄 -->
				<div v-if="orderDetailInfo.receiveType==2 &&orderDetailInfo.printMode==1">
					<div class="text-14 blod">Mailing Information</div>
					<div class="text-14 rowCenter" style="padding-top: 12px;padding-bottom: 5px;">
						<div>{{orderDetailInfo.receiverName}}</div>
						<div class="margin-left-10">{{orderDetailInfo.receiverTel}}</div>
					</div>
					<div class="text-12 gray">{{orderDetailInfo.receiveAddress}}</div>
				</div>
				<!-- 自提 -->
				<div v-if="orderDetailInfo.receiveType==1 &&orderDetailInfo.printMode==1">
					<div class="text-14 blod">self pickup information</div>
					<div class="text-14 rowCenter" style="padding-top: 12px;">
						<div style="width: 70px;">phone:</div>
						<div>{{orderDetailInfo.receiverTel}}</div>
					</div>
					<div class="text-14 flex-row" style="padding-top: 12px;">
						<div style="width: 70px;">site address:</div>
						<div>
							<div class="blod">{{orderDetailInfo.siteName}}</div>
							<div class="text-12 text999">{{orderDetailInfo.siteAddress}}</div>
						</div>
					</div>
					<div class="text-14 rowCenter" style="padding-top: 12px;">
						<div style="width: 70px;">self pickup time:</div>
						<div>{{orderDetailInfo.receiveTime}}</div>
					</div>
					
				</div>
				
			</div>
			
			<!-- 补打证书 -->
			<div class="width82 backgroundWhite radius5 margin-top-10">
				<div class="text-14 borderBottom padding-bottom-10 blod">
					supplementary certificate printing
				</div>
				<div class="borderBottom padding-top-10">
					<!-- <div v-if="item.mode=='自提'" class="text-14 blue padding-bottom-8">自提时间:{{item.selfTime}}</div> -->
					<div v-for="(list,key) in orderDetailInfo.certificateJson" :key="key" class="flex-row padding-bottom-10">
						<div>
							<van-image fit="cover" class="img80" :src="list.certificateImgUrl"></van-image>
						</div>
						<div class="text-12 margin-left-10 flexWidth"  @click="toCertificateDetail(list.id)">
							<div>certificate number:{{list.certificateNumber}}</div>
							<div class="padding-top-5">identification results:{{list.checkResult}}</div>
							<div class="padding-top-5">total mass:{{list.totalMass}}</div>
							<div class="padding-top-5">num:{{list.num}}</div>
							<div class="padding-top-5 between">
								<div>color:{{list.color}}</div>
								<div class="rowCenter gray">
									<div>details</div>
									
									<van-icon name="arrow" />
								</div>
								
							</div>
						</div>
					</div>
				</div>
				<div class="paddingtb10 text-12 borderBottom">
					<div class="betweenCenter">
						<div>certificate cost</div>
						<div>￥{{Number(orderDetailInfo.certificateCost).toFixed(2) }}</div>
					</div>
					<div class="betweenCenter padding-top-5" v-if="orderDetailInfo.receiveType==2&&orderDetailInfo.printMode==1">
						<div>mail cost</div>
						<div>￥{{Number(orderDetailInfo.mailCost).toFixed(2) }}</div>
					</div>
				</div>
				<div class="padding-top-10 text-12" style="text-align: right;">
					<span>total:</span>
					<span class="orange">{{orderDetailInfo.certificateNumber}}</span>
					<span style="margin-left: 8px;">price:</span>
					<span class="orange blod">￥{{Number(orderDetailInfo.cost).toFixed(2) }}</span>
				</div>
			</div>
			
			<!-- 订单信息 -->
			<div class="width82 backgroundWhite radius5 margin-top-10">
				<div class="text-14 padding-bottom-10 blod">
					order info
				</div>
				<div class="paddingtb10 text-14" style="color: #686868;">
					<div>
						order status:{{navList[orderDetailInfo.state].name}}
					</div>
					<div class="betweenCenter padding-top-5">
						order number:{{orderDetailInfo.orderCode}}
					</div>
					<div class="betweenCenter padding-top-5" v-if="orderDetailInfo.state!=0&&orderDetailInfo.state!=4">
						<!-- 支付方式 1 微信支付 2余额支付  3 其他方式 -->
						pay type:{{orderDetailInfo.payType==1?"WeChat":orderDetailInfo.payType==2?"balance":"other"}}
					</div>
					<div class="betweenCenter padding-top-5" v-if="orderDetailInfo.state!=0&&orderDetailInfo.state!=4">
						payment:￥{{Number(orderDetailInfo.cost).toFixed(2) }}
					</div>
					<div class="betweenCenter padding-top-5">
						create time:{{orderDetailInfo.createDate}}
					</div>
					<div class="betweenCenter padding-top-5" v-if="orderDetailInfo.state==3">
						confirmation time:{{orderDetailInfo.confirmDate}}
					</div>
				</div>
			</div>
			
			
			<!-- 快递信息 --><!-- 自提不展示 -->
			<div class="width82 backgroundWhite radius5 margin-top-10" v-if="orderDetailInfo.state==2&&orderDetailInfo.receiveType==2&&orderDetailInfo.printMode==1"><!--  -->
				<div class="text-14 padding-bottom-10 blod">
					express delivery information
				</div>
				<div class="paddingtb10 text-14 borderBottom" style="color: #686868;">
					
					<div>
						company:{{orderDetailInfo.expressName}}
					</div>
					<div class="betweenCenter padding-top-5" @click="copy()">
						<div>express code:{{orderDetailInfo.expressCode}}</div>
						<div>
							<img style="width: 12px;height: 12px;" src="../../../assets/images/copy.png" />
						</div>
					</div>
					
				</div>
			</div>
			
			<div class="width82 backgroundWhite radius5 margin-top-10" v-if="orderDetailInfo.qrcodeImgUrl"><!--  -->
				<div class="text-14 padding-bottom-10 blod">
					QrCode
				</div>
				<img style="width: 150px;height: 150px;" :src="orderDetailInfo.imgQrCode"/>
			</div>
			
			<!-- 底部按钮 -->
			<div style="height: 50px;"></div>
			<div class="detailBottom" v-if="orderDetailInfo.state==0">
				<div class="solidBtn" style="position: absolute;right: 5vw;bottom: 5px;"  @click="clickCancel">cancel</div>
			</div>

			<div class="detailBottom" v-if="orderDetailInfo.state==2">
				<div class="solidBtn" style="position: absolute;right: 5vw;bottom: 5px;" @click="clickReceiving">confirm receipt</div>
			</div>
			
		</div>
	</div>
	
</template>

<script>
	import BaseView from "@/views/BaseView.vue"
	import loadingBig from "@/components/loading-big.vue"
	import { Dialog } from 'vant';
	import { Toast } from 'vant';
	export default {
		extends: BaseView,
		components:{
			loadingBig
		},
		data() {
			return {
				title: "order detail",//订单详情
				state: 0,
				loadingBig:true,//页面加载
				showTips:false,
				showReceiving:false,
				tabList: ["to be confirmed", "to be shipped", "to be received", "completed", "canceled",""],
				navList:[{
					name:"to be confirmed",
					// name:"待确认",
					img:require("@/assets/images/certificateOrder/certificateOrder1.png")
				},
				{
					name:"to be shipped",
					// name:"待发货",
					img:require("@/assets/images/certificateOrder/certificateOrder2.png")
				},
				{
					name:"to be received",
					// name:"待收货",
					img:require("@/assets/images/certificateOrder/certificateOrder3.png")
				},
				{
					name:"completed",
					// name:"已完成",
					img:require("@/assets/images/certificateOrder/certificateOrder4.png")
				},
				{
					name:"canceled",
					// name:"已取消",
					img:require("@/assets/images/certificateOrder/certificateOrder5.png")
				}],
				orderId:0,
				orderDetailInfo:""
			}
		},
		created() {
			this.state = parseInt(this.$route.query.state) + 1
			this.orderId=this.$route.query.orderId
			this.getOrderDetail()
		},
		methods: {
			//查询详情
			getOrderDetail(){
				this.loadingBig=true
				this.$axios.get("/certificate/certificateOrder/certificateOrderDetails",{
					params:{
						certificateOrderId:this.orderId
					}
				}).then(res=>{
					console.log("订单详情",res)
					setTimeout(()=>{
						this.loadingBig=false
					},500)
					var data=res.data
					data.imgQrCode= "data:image/gif;base64," +data.qrcodeImgUrl
					this.orderDetailInfo=data
				})
			},
			//取消订单
			clickCancel() {
				Dialog.confirm({
					title: 'prompt',
					 message: 'Confirm to cancel the supplementary printing of this certificate?',
					// message: '确定取消本次证书补打?',
					confirmButtonColor: this.defaultColor,
					confirmButtonText:"determine",//确定
					cancelButtonText:"cancellation",//取消
				})
				.then(() => {
					// on confirm
					this.$axios.get("/certificate/certificateOrder/cancelOrder",{
						params:{
							orderId:this.orderId
						}
					}).then(res=>{
						console.log("取消订单",res)
						Toast(res.msg)
						this.getOrderDetail()
					})
				})
				.catch(() => {
					// on cancel
				});
			},
			//收货
			clickReceiving(){
				Dialog.confirm({
					title: 'prompt',
					message: 'Confirm receipt?',
					confirmButtonColor: "#0080FD",
					confirmButtonText:"determine",//确定
					cancelButtonText:"cancellation",//取消
				})
				.then(() => {
					// on confirm
					this.$axios.get("/certificate/certificateOrder/confirmOrder",{
						params:{
							orderId:this.orderId
						}
					}).then(res=>{
						console.log("取消订单",res)
						Toast(res.msg)
						this.getOrderDetail()
					})
				})
				.catch(() => {
					// on cancel
				});
				
			},
			//跳转至证书详情
			toCertificateDetail(id){
				this.$router.push({
					path:"/certificateDetail",
					query:{
						certificateId:id
					}
				})
			},
			//复制
			copy() {
				
				this.$copyText(this.orderDetailInfo.expressCode).then(function(e) {
					console.log('复制成功', e)
					Toast("success")
				}, function(e) {
					console.log("复制失败", e)
					Toast("fail")
				})
			}
		}
	}
</script>

<style>
	.orderDetailTop {
		width: 100vw;
		height: 170px;
		background-image: url(../../../assets/images/prsonal.png);
		background-size: 100% 100%;
		color: #FFFFFF;
		text-align: center;
	}
	.detailBottom {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 90vw;
		height: 30px;
		background: #FFFFFF;
		padding: 5px 5vw;
		font-size: 16px;
	}
</style>
