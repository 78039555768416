import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '@/views/tabbar/index.vue'
import Login from '@/views/login.vue'
import Register from '@/views/register.vue'
import Site from '@/views/home/site.vue'
import Subscribe from '@/views/home/subscribe.vue'
import SetMeal from '@/views/home/setMeal.vue'

import CertificateQuery from '@/views/tabbar/certificateQuery.vue' /* 证书查询 */
import CertificateDetail from '@/views/certificateQuery/certificateDetail.vue'
import CertificateOrder from '@/views/certificateQuery/certificateOrder.vue'
import MyCertificate from '@/views/tabbar/myCertificate.vue' /* 我的证书 */
import Prsonal from '@/views/tabbar/my.vue'
import Adress from '@/views/my/adress/adress.vue'
import AdressDetail from '@/views/my/adress/adressDetail.vue'
import SetUp from '@/views/my/setUp.vue'
import Authentication from '@/views/my/authentication.vue'
import Opinion from '@/views/my/opinion/opinion.vue'
import OpinionInput from '@/views/my/opinion/opinionInput.vue'
import Means from '@/views/my/means.vue'
import Agreement from '@/views/my/agreement.vue'

import SubmissionOrder from '@/views/my/submissionOrder/submissionOrder.vue'
import SubmissionOrderDetail from '@/views/my/submissionOrder/submissionOrderDetail.vue'

import Balance from '@/views/my/balance/balance.vue'
import BalanceRecord from '@/views/my/balance/record.vue'

import Integral from '@/views/my/integral.vue'
import Quality from '@/views/my/quality.vue'

import MonthlyStatement from '@/views/my/monthlyStatement/monthlyStatement.vue'
import MonthlyStatementRecord from '@/views/my/monthlyStatement/record.vue'

import Bookkeeping from '@/views/my/bookkeeping/bookkeeping.vue'
import BookkeepingRecord from '@/views/my/bookkeeping/record.vue'

import PackageRecord from '@/views/my/packageRecord.vue'

import Sample from '@/views/my/sample/sample.vue'
import AddSample from '@/views/my/sample/addSample.vue'

import MyCertificateOrder from '@/views/my/certificateOrder/myCertificateOrder.vue'
import CertificateOrderDetail from '@/views/my/certificateOrder/certificateOrderDetail.vue'

import OrderImg from '@/views/home/image.vue'

Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'Index',
		component: Index,
		meta: {
			title: 'home',
			keepAlive: true // 需要缓存 首页
		}
	},
	{
		path: '/login',
		name: 'Login',
		component: Login
	},
	{
		path: '/register',
		name: 'Register',
		component: Register
	},
	{
		path: '/setMeal',
		name: 'SetMeal',
		component: SetMeal
	},
	{
		path: '/Subscribe',
		name: 'Subscribe',
		component: Subscribe,
		meta: {
			title: '送检预约',
			keepAlive: true // 需要缓存
		}
	},
	{
		path: '/site',
		name: 'Site',
		component: Site
	},
	{
		path: '/agreement',
		name: 'Agreement',
		component: Agreement
	},
	{
		path: '/certificateQuery',
		name: 'CertificateQuery',
		component: CertificateQuery,
		meta: {
			title: "Certificate Query",
		}
	},
	{
		path: '/certificateDetail',
		name: 'CertificateDetail',
		component: CertificateDetail
	},
	{
		path: '/certificateOrder',
		name: 'CertificateOrder',
		component: CertificateOrder,
		meta: {
			title: "确认补打",
			keepAlive: true,// 需要缓存
		}
	},
	{
		path: '/myCertificate',
		name: 'MyCertificate',
		component: MyCertificate
	},
	{
		path: '/prsonal',
		name: 'Prsonal',
		component: Prsonal
	},
	{
		path: '/adress',
		name: 'Adress',
		component: Adress
	},
	{
		path: '/adressDetail',
		name: 'AdressDetail',
		component: AdressDetail
	},
	{
		path: '/setUp',
		name: 'SetUp',
		component: SetUp
	},
	{
		path: '/authentication',
		name: 'Authentication',
		component: Authentication
	},
	{
		path: '/opinion',
		name: 'Opinion',
		component: Opinion
	},
	{
		path: '/opinionInput',
		name: 'OpinionInput',
		component: OpinionInput
	},
	{
		path: '/submissionOrder',
		name: 'SubmissionOrder',
		component: SubmissionOrder
	},
	{
		path: '/submissionOrderDetail',
		name: 'SubmissionOrderDetail',
		component: SubmissionOrderDetail
	},
	{
		path: '/balance',
		name: 'Balance',
		component: Balance
	},
	{
		path: '/balanceRecord',
		name: 'BalanceRecord',
		component: BalanceRecord
	},
	{
		path: '/integral',
		name: 'Integral',
		component: Integral
	},
	{
		path: '/quality',
		name: 'Quality',
		component: Quality
	},
	{
		path: '/monthlyStatement',
		name: 'MonthlyStatement',
		component: MonthlyStatement
	},
	{
		path: '/monthlyStatementRecord',
		name: 'MonthlyStatementRecord',
		component: MonthlyStatementRecord
	},
	{
		path: '/bookkeeping',
		name: 'Bookkeeping',
		component: Bookkeeping
	},
	{
		path: '/bookkeepingRecord',
		name: 'BookkeepingRecord',
		component: BookkeepingRecord
	},
	{
		path: '/packageRecord',
		name: 'PackageRecord',
		component: PackageRecord
	},
	{
		path: '/sample',
		name: 'Sample',
		component: Sample
	},
	{
		path: '/addSample',
		name: 'AddSample',
		component: AddSample
	},
	{
		path: '/myCertificateOrder',
		name: 'MyCertificateOrder',
		component: MyCertificateOrder
	},
	{
		path: '/certificateOrderDetail',
		name: 'CertificateOrderDetail',
		component: CertificateOrderDetail
	},
	{
		path: '/means',
		name: 'Means',
		component: Means
	},
	{
		path: '/orderImg',
		name: 'OrderImg',
		component: OrderImg
	}
]

const router = new VueRouter({
	mode: 'hash',
	base: process.env.BASE_URL,
	routes
})

export default router
router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    if (to.meta.title) {
        document.title = to.meta.title
    }
    next()
})
