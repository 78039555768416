<template>
	<div>
		<!-- 搜索前 -->
		<div v-if="searchState==0">
			
			
			
			<!-- logo -->
			<div style="width: 53.3vw;margin: auto;margin-top: 14px;">
				<img style="width: 53.3vw;margin: auto;" src="../../assets/images/logo.png" />
			</div>

			<!-- 搜索 -->
			<div class="flex-row width90 input" style="margin-top: 22px;">
				<van-field class="searchInput" style="height: 34px;background: #F7F8FA;" v-model="certificateCode"
					:clearable="true" left-icon="search" :right-icon="require('@/assets/images/scanCode.png')"
					@click-right-icon="sacn" @clear="clearSearch" placeholder="Please enter the certificate number" />
				<div class="search" @click="search">search</div>
			</div>

			<div style="width: 41.6vw;margin: auto;text-align: center;margin-top: 41px;" @click="sacn">
				<img style="width: 41.6vw;height: 40vw;" src="../../assets/images/sacnPhone.png" />
				<!-- <div class="text-18 margin-top-10">Beware of imitations!</div> -->
			</div>

			<div class="width90" style="color: #A5A6AB;margin-top: 34px;">
				<div class="text-16">Certificate Query Method</div>
				<div class="text-14">
					1. Scan QR code query: Click on the "Scan" icon to open the scanning interface, and aim the phone camera at the QR code in the lower right corner of the certificate for scanning.
				</div>
				<div class="text-14">
					2. Input Inspection Number Query: Enter the complete inspection number in the box and click the "Query" button to query.
				</div>
			</div>
		</div>

		<!-- 证书详情 -->
		<div v-if="searchState==1">
			<!-- 搜索 -->
			<div class="flex-row width90 input"
				style="padding-top: 22px;position: fixed;top: 0;z-index: 2;right: 5vw;background: #FFFFFF;">
				<van-field class="searchInput" style="height: 34px;background: #F7F8FA;" v-model="certificateCode"
					:clearable="true" left-icon="search" :right-icon="require('@/assets/images/scanCode.png')"
					@click-right-icon="sacn" @clear="clearSearch" placeholder="Please enter the certificate number" />
				<div class="search" @click="search">search</div>
			</div>

			<div class="backgroundWhite" style="margin-top: 22px;height: 35px;"></div>
			<!-- 内容 -->
			<div v-if="loadingBig">
				<loadingBig></loadingBig>
			</div>
			<div class="width90 margin-top-10" v-else>
				<div class="text-14 blod">
					The query results are as follows:
				</div>

				<van-image fit="cover" class="contenImg" :src="certificateInfo.certificateImgUrl"
					@click="imagePreview([certificateInfo.certificateImgUrl])"></van-image>

				<div>
					<div class="between borderBottom paddingtb10 text-14">
						<!-- 鉴定结果 -->
						<div class="gray">Identification results</div>
						<div class="wrapDiv">{{certificateInfo.checkResult}}</div>
					</div>

					<div class="between borderBottom paddingtb10 text-14">
						<!-- 证书编号 -->
						<div class="gray">Certificate number</div>
						<div class="wrapDiv">{{certificateInfo.certificateNumber}}</div>
					</div>

					<div class="between borderBottom paddingtb10 text-14">
						<!-- 总质量 -->
						<div class="gray">Total mass</div>
						<div class="wrapDiv">{{certificateInfo.totalMass}}</div>
					</div>

					<div class="between borderBottom paddingtb10 text-14">
						<!-- 颜色 -->
						<div class="gray">color</div>
						<div class="wrapDiv">{{certificateInfo.color}}</div>
					</div>
					
					<div class="between borderBottom paddingtb10 text-14">
						<!-- 样式 -->
						<div class="gray">Cut</div>
						<div class="wrapDiv">{{certificateInfo.style}}</div>
					</div>

					<div class="between borderBottom paddingtb10 text-14">
						<!-- 形状 -->
						<div class="gray">shape</div>
						<div class="wrapDiv">{{certificateInfo.shape}}</div>
					</div>

					<!-- 玉石  certificateType    证书类型1 珠宝玉石证书  2 贵金属证书-->
					<!-- <div class="between borderBottom paddingtb10 text-14" v-if="certificateInfo.certificateType==1">
						放大检查
						<div class="gray">Zoom in for inspection</div>
						<div class="wrapDiv">{{certificateInfo.enlargeCheck}}</div>
					</div>

					<div class="between borderBottom paddingtb10 text-14" v-if="certificateInfo.certificateType==1">
						紫外可见光谱
						<div class="gray">UV visible spectrum</div>
						<div class="wrapDiv">{{certificateInfo.ultravioletVisibleSpectrum}}</div>
					</div> -->

					<!-- <div class="between borderBottom paddingtb10 text-14" v-if="certificateInfo.certificateType==1">
						<div class="gray">红外图谱（图片）</div>
						<div></div>
					</div>
					<div class="between borderBottom paddingtb10 text-14" v-if="certificateInfo.certificateType==1">
						<div class="gray">尺寸</div>
						<div>尺寸</div>
					</div>
					<div class="between borderBottom paddingtb10 text-14" v-if="certificateInfo.certificateType==1">
						<div class="gray">光线特征</div>
						<div>光线特征</div>
					</div>
					<div class="between borderBottom paddingtb10 text-14" v-if="certificateInfo.certificateType==1">
						<div class="gray">吸收光谱（图片）</div>
						<div class="solidBtn">查看图片</div>
					</div> -->

					<!-- <div class="between borderBottom paddingtb10 text-14" v-if="certificateInfo.certificateType==1">
						红外检测描述
						<div class="gray">infrared detection description</div>
						<div class="wrapDiv">{{certificateInfo.infraredDescription}}</div>
					</div>

					<div class="between borderBottom paddingtb10 text-14" v-if="certificateInfo.certificateType==1">
						折射率
						<div class="gray">refractive</div>
						<div class="wrapDiv">{{certificateInfo.refractive}}</div>
					</div> -->

					<div class="between borderBottom paddingtb10 text-14" v-if="certificateInfo.certificateType==1">
						<!-- 密度--对象 -->
						<div class="gray">object</div>
						<div class="wrapDiv">{{certificateInfo.density}}</div>
					</div>

					<!-- <div class="between borderBottom paddingtb10 text-14" v-if="certificateInfo.certificateType==1">
						是否测配石数
						<div class="gray">test mix stone</div>
						<div class="wrapDiv">{{certificateInfo.checkStoneNumber==1?"是":"否"}}</div>
					</div> -->
					<!-- 完 -->

					<!-- 金属 -->
					<div class="between borderBottom paddingtb10 text-14" v-if="certificateInfo.certificateType==2">
						<!-- 印记 -->
						<div class="gray">imprint</div>
						<div class="wrapDiv">{{certificateInfo.imprint}}</div>
					</div>
					<!-- 完 -->
					
					<div class="between borderBottom paddingtb10 text-14">
						<!-- 其他--产地 -->
						<div class="gray margin-right-10">origin</div>
						<div class="wrapDiv">{{certificateInfo.other}}</div>
					</div>
					<div class="between borderBottom paddingtb10 text-14">
						<!-- 国家 -->
						<div class="gray">from</div>
						<div class="wrapDiv">{{certificateInfo.lightFeatures}}</div>
					</div>

					

					<div class="between borderBottom paddingtb10 text-14">
						<!-- 备注 -->
						<div class="gray margin-right-10">cnote</div>
						<div class="wrapDiv">{{certificateInfo.cnote}}</div>
					</div>

				</div>


				<div v-for="(img,key) in certificateInfo.sampleImgUrls" :key="key"
					>
					<van-image fit="cover" class="contenImg" :src="img.cutUrl" @click="imagePreview([img.cutUrl])"></van-image>
				</div>


			</div>
		</div>



		<tabbar></tabbar>
	</div>
</template>

<script>
	import {
		Toast
	} from 'vant';
	import BaseView from "@/views/BaseView.vue"
	import Tabbar from "@/components/tabbar.vue"
	import loadingBig from "@/components/loading-big.vue"
	import wx from 'weixin-js-sdk'
	import {
		ImagePreview
	} from 'vant';
	export default {
		extends: BaseView,
		components: {
			Tabbar,
			loadingBig
		},
		data() {
			return {
				title: "Certificate Query",//证书查询
				backcolor: "#FFFFFF",
				loadingBig: true, //页面加载
				searchState: 0, //搜索页面展示，0默认或者搜索不到证书，1搜索到证书，展示证书详细
				state: 0, //0翡翠1黄金
				certificateCode: "",
				certificateInfo: ""
			}
		},
		created() {

			if (this.init_wx) {
				var url = "https://board.ebaoku.cugi.net/#/login"
				// var url=window.location.href
				this.$axios.post("wx/h5Pay/wxH5Config?url=" + url).then(res => {
					console.log("微信配置", res)
					this.init_wx = false
					if (res.code == 1) {
						wx.config({
							debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
							appId: res.data.appId, // 必填，公众号的唯一标识
							timestamp: res.data.timestamp, // 必填，生成签名的时间戳
							nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
							signature: res.data.signature, // 必填，签名
							jsApiList: ['scanQRCode', 'getLocation'] // 必填，需要使用的JS接口列表
						});

					} else {
						Toast(res.msg)
					}
				})
			}

			var code = this.$route.query.code
			console.log("code", code)
			if (code) {
				this.certificateCode = code
				this.search()
			}
		},
		methods: {
			//扫码
			sacn() {
				console.log("扫码")
				var that = this
				wx.scanQRCode({
					needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
					scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
					success: function(res) {
						// 成功啦
						console.log("扫码成功", res)
						var url = res.resultStr
						var param=that.GetRequest(url)
						console.log("扫码成功param", param)
						that.certificateCode = param.code
						that.search()
					},
					error: function(err) {
						// 这里把err打出来可以看到结果
						// 根据打出的结果就知道了   有用给个赞哦
						console.log("扫码失败", err)

					}
				});
			},
			GetRequest(url) {
				if (url.indexOf('?') != -1) {
					let p = url.split('?')[1]
					let keyValue = p.split('&');
					console.log("keyValue",keyValue)
					let obj = {};
					for (let i = 0; i < keyValue.length; i++) {
						let item = keyValue[i].split('=');
						let key = item[0];
						let value = item[1];
						obj[key] = value;
					}
					console.log("obj",obj)
					return obj;
				} else {
					console.log('没有参数');
					alert('没有参数');
					return null;
				}
			},
			//搜索
			search() {
				this.loadingBig = true
				console.log("搜索", this.certificateCode)
				this.$axios.get("certificate/findByNumber/certificateByNumber", {
					params: {
						certificateNumber: this.certificateCode
					}
				}).then(res => {
					console.log("查询证书", res)
					setTimeout(() => {
						this.loadingBig = false
					}, 1000)
					// this.loadingBig=false
					if (res.data == "") {
						Toast.fail('Query has no results');
					} else {
						window.scrollTo(0, 0);
						var certificateInfo=res.data
						// var str=certificateInfo.shape.split(",")
						// certificateInfo.style=str[0]//样式
						// certificateInfo. fashion=str[1]//形状

						this.certificateInfo = certificateInfo
						this.searchState = 1
						this.certificateInfo.certificateImgUrl = res.data.certificateImgUrl
						var img = []
						if (res.data.sampleImgUrls) {
							img = res.data.sampleImgUrls.split(",")
						}

						// var imgArr=[]
						// img.map(value=>{
						// 	imgArr.push(this.imageUrl+value)
						// })

						this.certificateInfo.detailImgs = img
					}
				})
				// Toast.fail('查询无结果');
				// this.$router.push({
				// 	path:"/certificateDetail"
				// })
			},
			//清除搜索框
			clearSearch() {
				this.searchState = 0
			},
			//预览图片
			imagePreview(img) {
				console.log("预览图片", img)
				ImagePreview(img);
			}
		}

	}
</script>

<style>
	.searchInput {
		border-top-left-radius: 34px;
		border-bottom-left-radius: 34px;
	}

	.input .van-cell {
		line-height: 14px !important;
	}

	.search {
		background: #0080FD;
		color: #FFFFFF;
		width: 50px;
		height: 34px;
		border-top-right-radius: 34px;
		border-bottom-right-radius: 34px;
		text-align: center;
		line-height: 34px;
		font-size: 12px;
	}
	
	.van-image__img{
		border-radius: 0 !important;
	}
</style>
