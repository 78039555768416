<template>
	<div>
		<!-- <router-view /> -->
		<keep-alive>
		    <router-view v-if="$route.meta.keepAlive"></router-view>
		</keep-alive>
		<router-view v-if="!$route.meta.keepAlive"></router-view>
	</div>
</template>


<script>
	import Vue from 'vue';
	import {
		Toast
	} from 'vant';
	import wx from 'weixin-js-sdk'
	Vue.use(Toast);
	export default {
		data(){
			return{
				init_wx:true,
			}
		},
		created() {
			
			
		},
		mounted(){
			console.log("app_mounted")
			
			
		},
	}
</script>
<style>
	body {
		color: #343440;
		background: #F5F5F5;
	}
	.wrapDiv{
		word-wrap:break-word;
		word-break:break-all;
	}
	.one-line {
		display: -webkit-box;
		overflow: hidden;
		text-overflow: ellipsis;
		word-wrap: break-word !important;
		white-space: normal !important;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
	}
	.fixed{
		position: fixed;
	}
	/* 宽度 */
	.width90 {
		width: 90vw;
		margin: auto;
	}

	.width82 {
		width: 82vw;
		margin: auto;
		padding: 10px 4vw !important;
	}

	/* 背景色 */
	.backgroundWhite {
		background: #FFFFFF;
	}

	/* 圆角 */
	.radius10 {
		border-radius: 10px;
	}

	.radius5 {
		border-radius: 5px;
	}

	/* flex布局 */
	.flex-row {
		display: flex;
		flex-direction: row;
	}

	.rowCenter {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.between {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	.betweenCenter {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}

	.center {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	
	.wrap{
		flex-wrap: wrap;
	}

	/* flex布局宽度自适应 */
	.flexWidth {
		width: 0;
		flex: 1;
	}

	/* padding */
	.padding-15 {
		padding: 15px;
	}

	.padding-bottom-10 {
		padding-bottom: 10px;
	}

	.paddingtb8 {
		padding: 8px 0;
	}
	
	.padding-top-8 {
		padding-top: 8px;
	}

	.paddingtb10 {
		padding: 10px 0;
	}
	
	.paddingtb12 {
		padding: 12px 0;
	}

	.padding-top-10 {
		padding-top: 10px;
	}
	
	.padding-top-5 {
		padding-top: 5px;
	}

	.padding-bottom-8 {
		padding-bottom: 8px;
	}

	/* margin */
	.margin-left-8{
		margin-left: 8px;
	}
	.margin-10 {
		margin: 10px;
	}


	.margin-right-10 {
		margin-right: 10px;
	}

	.margin-left-10 {
		margin-left: 10px;
	}

	.margin-top-10 {
		margin-top: 10px;
	}

	.margin-bottom-10 {
		margin-bottom: 10px;
	}

	.margin-15 {
		margin: 15px;
	}

	.margin-right-15 {
		margin-right: 15px;
	}

	.margin-left-15 {
		margin-left: 15px;
	}

	.margin-top-15 {
		margin-top: 15px;
	}

	.margin-bottom-15 {
		margin-bottom: 15px;
	}

	/* 文字加粗 */
	.blod {
		font-weight: bold;
	}

	/* 文字大小 */
	.text-32 {
		font-size: 32px;
	}

	.text-24 {
		font-size: 24px;
	}
	
	.text-22 {
		font-size: 22px;
	}

	.text-20 {
		font-size: 20px;
	}

	.text-18 {
		font-size: 18px;
	}

	.text-16 {
		font-size: 16px;
	}

	.text-14 {
		font-size: 14px;
	}

	.text-12 {
		font-size: 12px;
	}

	/* 行高 */
	.line-height-17 {
		line-height: 17px;
	}

	/* 文字颜色 */
	.gray {
		color: #7D7E83;
	}

	.lightGray {
		color: #BEC0C4;
	}

	.orange {
		color: #FF6A15;
	}

	.yellow {
		color: #FFAB37;
	}

	.blue {
		color: #0080FD;
	}

	.text999 {
		color: #999999;
	}

	.white {
		color: #FFFFFF;
	}

	/* vant-image图片圆角 */
	.van-image__img {
		border-radius: 10px;
	}

	/* 图片大小 */
	.img14 {
		width: 14px;
		height: 14px;
	}

	.img16 {
		width: 16px;
		height: 16px;
	}

	.img80 {
		width: 80px;
		height: 80px;
	}

	/* 横线 */
	.borderBottom {
		border-bottom: #F5F5F5 1px solid;
	}

	/* vant组件 */
	.van-cell {
		padding: 10px 4vw;
	}

	/* 提示弹框 */
	.cancel {
		width: 80vw;
		background: #FFFFFF;
		border-radius: 10px;
	}

	.cancelBtn {
		width: 50%;
		height: 57px;
		line-height: 57px;
		text-align: center;
		color: #999999;
		border-top: #DDDDDD 1px solid;
		border-right: #DDDDDD 1px solid;
	}

	.determineBtn {
		width: 50%;
		height: 57px;
		line-height: 57px;
		text-align: center;
		border-top: #DDDDDD 1px solid;
	}

	/* 按钮 */
	.strokeBtn {
		width: 70px;
		height: 28px;
		border: #0080FD 1px solid;
		color: #0080FD;
		background: #FFFFFF;
		line-height: 28px;
		text-align: center;
		border-radius: 28px;
		font-size: 12px;
	}

	.solidBtn {
		width: 70px;
		height: 28px;
		color: #FFFFFF;
		background: linear-gradient(to right,#03B0F9,#0080FD);
		line-height: 28px;
		text-align: center;
		border-radius: 28px;
		font-size: 12px;
	}
	.orderBtn{
		width: 70px;
		height: 28px;
		text-align: center;
		line-height: 28px;
		border-radius: 28px;
		color: #FFFFFF;
		border: #FFFFFF 1px solid;
		font-size: 12px;
	}
	.payBtn{
		width: 82vw;
		height: 44px;
		background: linear-gradient(to right,#03B0F9,#0080FD);
		color: #FFFFFF;
		text-align: center;
		line-height: 44px;
		border-radius: 44px;
		margin: 30px 0;
	}
	/* 底部按钮位置 */
	.borderBtn {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 90vw;
		padding: 5px 5vw;
		background: #FFFFFF;
	}
</style>
