<template>
	<div>
		<div v-if="showSkeleton">
			<div class="width82 backgroundWhite radius5 margin-top-10" v-for="i in 6" :key="i">
				<van-skeleton :row="2" />
				<div class="borderBottom" style="margin: 10px 0;"></div>
				<van-skeleton :row="1" />
			</div>
		</div>
		<van-empty v-if="!showSkeleton&&opinionList.length<=0" class="custom-image" :image="emptyImg" description="You haven't received any feedback yet~" />
		<div v-if="!showSkeleton&&opinionList.length>0">
			<van-pull-refresh v-model="refreshing" @refresh="onRefresh">
				<van-list v-model="loading" :finished="finished" finished-text="There's no more" @load="onLoad" :immediate-check="false">
					<div class="width82 backgroundWhite radius5 margin-top-10" v-for="(item,index) in opinionList" :key="index">
						<div class="borderBottom text-16 padding-bottom-10">{{item.content}}</div>
						<div class="text-14 padding-top-10" style="color: #A5A6AB;">time:{{item.createDate}}</div>
					</div>
				</van-list>
			</van-pull-refresh>
		</div>




		<!-- 底部按钮 -->
		<div style="height: 60px;"></div>
		<div class="opinionBtn" @click="toOpinionInput">
			<van-button style="width: 90vw;" :color="colorBtn" type="primary" round>release</van-button>
		</div>
	</div>
</template>

<script>
	import BaseView from "@/views/BaseView.vue"
	export default {
		extends: BaseView,
		data() {
			return {
				title: "opinion",//意见反馈
				showSkeleton: true,
				emptyImg: require("@/assets/images/empty.png"),
				param: {
					page: 1
				},
				total: 0,
				opinionList: [],
				refreshing:false,
				loading:false,
				finished:false,
			}
		},
		created() {
			this.getOpinion()
		},
		methods: {
			//查询意见反馈列表
			getOpinion() {
				this.$axios.get("order/opinion/orderOpinionListPage", {
					params: this.param
				}).then(res => {
					this.showSkeleton = false
					this.refreshing = false
					this.loading = false
					console.log("意见反馈", res)
					if (res.code == 1) {
						if (this.param.page == 1) {
							this.opinionList = res.data
						} else {
							this.opinionList = this.opinionList.concat(res.data)
						}
						this.total = res.count
						if (this.total == this.opinionList.length) {
							this.finished=true
						}
					}
				})
			},
			//跳转发布意见
			toOpinionInput() {
				this.$router.push({
					path: "/opinionInput"
				})
			},
			onRefresh(){
				this.refreshing = true
				this.finished = false
				// this.showSkeleton=true
				this.param.page=1
				// this.opinionList=[]
				this.getOpinion()
			},
			onLoad(){
				if (this.total <= this.opinionList.length) {
					this.finished=true
					return;
				}
				this.param.page++
				this.getOpinion()
			}
		}
	}
</script>

<style>
	/* 底部按钮 */
	.opinionBtn {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 90vw;
		padding: 5px 5vw;
		background: #FFFFFF;
	}
</style>
