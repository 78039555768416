<template>
	<div>
		<div v-if="showSkeleton">
			<div class="width82 backgroundWhite radius5 margin-top-10" v-for="i in 6" :key="i">
				<van-skeleton :row="2" />
				<div class="borderBottom" style="margin: 10px 0;"></div>
				<van-skeleton :row="1" />
			</div>
		</div>
		<van-empty v-if="!showSkeleton&&adressList.length<=0" class="custom-image" :image="emptyImg" description="You don't have a shipping address yet~" />
		<div v-if="!showSkeleton&&adressList.length>0">
			<van-pull-refresh v-model="refreshing" @refresh="onRefresh">
				<van-list v-model="loading" :finished="finished" finished-text="There's no more" @load="onLoad" :immediate-check="false">
					<van-radio-group v-model="adressRadio">
						<div class="width82 backgroundWhite radius5 margin-top-10" v-for="(item,index) in adressList" :key="index">
							<div class="borderBottom" style="padding-bottom: 10px;" @click="clickAdress(item)">
								<div class="rowCenter text-16 blod">
									<div>{{item.contactName}}</div>
									<div style="margin-left: 20px;">{{item.telephone}}</div>
								</div>
								<div class="gray text-14">
									{{item.region}}{{item.address}}
								</div>
							</div>
							<div style="padding-top: 10px;" class="between text-12">
								<div>
									<van-radio icon-size="15px" @click="clickRadio(item)" :name="item.id">default</van-radio>
								</div>
								<div class="rowCenter">
									<div class="rowCenter" @click="toDetail(item)">
										<img style="width: 10px;height: 10px;margin-right: 5px;" src="../../../assets/images/edit.png" />
										<div>edit</div>

									</div>
									<div class="rowCenter" style="margin-left: 30px;" @click="deleteAdress(item)">
										<img style="width: 10px;height: 10px;margin-right: 5px;" src="../../../assets/images/delete.png" />
										<div>delete</div>
									</div>
								</div>
							</div>
						</div>
					</van-radio-group>
				</van-list>
			</van-pull-refresh>
		</div>

		<div style="height: 60px;"></div>
		<div class="adressBtn" @click="addAdress">
			<van-button style="width: 90vw;" :color="colorBtn" type="primary" round>add address</van-button>
		</div>

		
	</div>
</template>

<script>
	import BaseView from "@/views/BaseView.vue"
	import {bus} from "@/bus.js"
	import {Toast} from 'vant';
	import { Dialog } from 'vant';
	export default {
		extends: BaseView,
		data() {
			return {
				title: "Address Management",//地址管理
				showSkeleton: true,
				adressRadio: 1, //1默认地址
				refreshing:false,
				finished:false,
				loading:false,
				showTips: false,
				emptyImg: require("@/assets/images/empty.png"),
				adressId:0,
				param: {
					page: 1
				},
				adressList: [],
				adressState:0//1为选择地址，需要返回上一页
			}
		},
		created() {
			this.adressState=this.$route.query.adressState
			this.getAdressList()
		},
		methods: {
			//查询地址
			getAdressList() {
				this.$axios.get("member/address/addressList", {
					params:this.param
				}).then(res => {
					console.log("地址列表", res)
					this.showSkeleton = false
					this.refreshing = false
					this.loading = false
					if (res.code == 1) {
						if (this.param.page == 1) {
							this.adressList = res.data
						} else {
							this.adressList = this.adressList.concat(res.data)
						}
						this.total = Number(res.count) 
						if(this.adressList.length<=this.total){
							this.finished=true
						}
						this.adressList.forEach((value,index)=>{
							if(value.defaults==1){
								this.adressRadio=value.id
							}
						})
					}

				})
			},
			//设置默认地址
			setDefaultAddress(id){
				let param={
					addressId:id
				}
				this.$axios.post("member/address/setDefaultAddress",param).then(res=>{
					console.log("修改默认地址",res)
					Toast(res.msg)
					this.onRefresh()
				})
			},
			//选择地址
			clickAdress(item){
				if(this.adressState==1){
					bus.$emit("setAdress",item)
					localStorage.setItem("setAdress",JSON.stringify(item))
					this.$router.back()
					
				}else{
					this.$router.push({
						path:"/adressDetail",
						query:{
							addressId:item.id
						}
					})
				}
				
			},
			//跳转详情
			toDetail(item){
				this.$router.push({
					path:"/adressDetail",
					query:{
						addressId:item.id
					}
				})
			},
			clickRadio(value) {
				console.log("单选", value)
				this.adressRadio=value.id
				this.setDefaultAddress(value.id)
			},
			//跳转新增地址
			addAdress() {
				this.$router.push({
					path: "/adressDetail"
				})
			},
			//删除地址
			deleteAdress(item) {
				Dialog.confirm({
					title: 'delete',
					message: 'Do you want to delete this address?',
					confirmButtonColor: this.defaultColor,
					confirmButtonText:"determine",//确定
					cancelButtonText:"cancellation",//取消
				})
				.then(() => {
					// on confirm
					this.$axios.delete("member/address/delAddress",{params: {
						addressId:item.id
					}}).then(res=>{
						Toast(res.msg)
						if(res.code==1){
							this.onRefresh()
						}
					})
				})
				.catch(() => {
					// on cancel
				});
			},
			onRefresh(){
				this.refreshing = true
				this.finished = false
				// this.showSkeleton=true
				this.param.page=1
				// this.adressList=[]
				this.getAdressList()
			},
			onLoad(){
				if(this.adressList.length<=this.total){
					this.finished=true
					return;
				}
				this.param.page++
				this.getAdressList()
			}
		}
	}
</script>

<style>
	.custom-image .van-empty__image {
		width: 58.7vw;
		height: 42.7vw;
	}

	/* 底部按钮 */
	.adressBtn {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 90vw;
		padding: 5px 5vw;
		background: #FFFFFF;
	}
</style>
