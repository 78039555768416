<template>
	<div>
		<div class="monthlyStatement">
			<div style="width: 100%;position: relative;">
				<div>
					<div class="text-20">￥{{billAllMoney.meltMoney}}</div>
					<!-- 月结金额 -->
					<div class="text-14">Monthly settlement amount</div>
					<!-- 出账日期 -->
					<div class="text-12" style="padding-top: 12px;">statement date:{{billAllMoney.billingDate}}</div>
				</div>
				<div style="position: absolute;top: 0;right: 5vw;">
					<div class="">
						<!-- {{billAllMoney.state==1?"已销账":billAllMoney.state==2?"未销账":"未出账"}} -->
						{{billAllMoney.state==1?"reimbursing":billAllMoney.state==2?"uncollected":"outstanding"}}
					</div>
				</div>
			</div>

		</div>

		<!-- 账单明细 -->
		<div>
			<div style="padding: 8px 5vw;">Bill Details</div>

			<div>
				<div v-if="showSkeleton">
					<div class="width82 backgroundWhite radius5 margin-bottom-10" v-for="i in 5" :key="i">
						<van-skeleton :row="3" />
					</div>

				</div>
				<van-empty v-if="!showSkeleton&&monthlyStatementList.length<=0" class="custom-image" :image="emptyImg"
					description="You don't have a bill yet" />
				<div v-if="!showSkeleton&&monthlyStatementList.length>0">
					<van-pull-refresh v-model="refreshing" @refresh="onRefresh">
						<van-list v-model="loading" :finished="finished" finished-text="There's no more" @load="onLoad">
							<div class="width82 backgroundWhite radius5 margin-bottom-10"
								v-for="(item,index) in monthlyStatementList" :key="index">
								<div class="text-14">
									order code:{{item.orderCode}}
								</div>
								<div class="text-14 padding-top-5">
									price:￥{{item.totalCost}}
								</div>
								<div class="text-14 padding-top-5">
									time:{{item.confirmDate}}
								</div>
							</div>
						</van-list>
					</van-pull-refresh>
				</div>

			</div>
		</div>

		<div style="height: 60px;"></div>
		<div class="borderBtn" @click="checkOut">
			<van-button :disabled="disableBtn" style="width: 90vw;" :color="colorBtn" type="primary" round>
				proceed to checkout
			</van-button>
		</div>

		<!-- 支付方式 -->
		<van-action-sheet v-model="showPayment" title="pay type">
			<payment :price="billAllMoney.meltMoney" :orderType="6" :orderId="param.billMonthId" :showOffline="false" :showIntegral="false" :showFrequency="false" @paySuccess="paySuccess"></payment>
		</van-action-sheet>
	</div>
</template>

<script>
	import BaseView from "@/views/BaseView.vue"
	import payment from "@/components/payment.vue"
	export default {
		extends: BaseView,
		components: {
			payment
		},
		data() {
			return {
				title: "monthly settlement form",//月结账单
				showPayment: false,
				payPrice: 180,
				showSkeleton: true,
				refreshing: false,
				loading: false,
				finished: false,
				disableBtn: false,
				param:{
					billMonthId:0,
				},
				billAllMoney:{},
				monthlyStatementList: [],
				total:0
			}
		},
		created() {
			this.param.billMonthId=this.$route.query.orderId
			this.getList()
			this.getBillAllMoney()
		},
		methods: {
			//月结账单明细
			getList(){
				this.$axios.get("/bill/month/billDetails",{
					params:this.param
				}).then(res=>{
					console.log("账单明细",res)
					this.showSkeleton = false
					this.refreshing=false
					this.loading=false
					if(this.param.page==1){
						this.monthlyStatementList=res.data
					}else{
						this.monthlyStatementList=this.monthlyStatementList.concat(res.data)
					}
					// this.total=res.count
					if(this.total<=this.monthlyStatementList.length){
						this.finished=true
					}
				})
			},
			//月结账单统计
			getBillAllMoney(){
				this.$axios.get("/bill/month/billAllMoney",{
					params:{
						billMonthId:this.param.billMonthId
					}
				}).then(res=>{
					console.log("月结账单统计",res)
					this.billAllMoney=res.data
					if(this.billAllMoney.state!=2){
						this.disableBtn=true
					}
				})
			},
			//结账
			checkOut() {
				if(this.disableBtn) return;
				this.showPayment = true
			},
			//支付成功
			paySuccess(){
				this.showPayment = false
				this.onRefresh()
				this.getBillAllMoney()
			},
			onRefresh(){
				this.refreshing=true
				this.finished=false
				// this.showSkeleton=true
				// this.monthlyStatementList=[]
				this.param.page=1
				this.getList()
			},
			onLoad(){
				if(this.total<=this.monthlyStatementList.length){
					this.finished=true
					return;
				}
				this.param.page++
				this.getList()
			}
		}
	}
</script>

<style>
	.monthlyStatement {
		width: 100vw;
		height: 120px;
		background: url(../../../assets/images/bill.png);
		background-size: 100% 100%;
		text-align: center;
		color: #FFFFFF;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.balanceWords {
		width: 85px;
		margin: auto;
		color: #FFFFFF;
		padding-top: 24px;
		text-align: center;
	}

	.billBtn {
		width: 70px;
		height: 28px;
		text-align: center;
		line-height: 28px;
		border-radius: 28px;
		color: #FFFFFF;
		border: #FFFFFF 1px solid;
		font-size: 12px;
	}
</style>
