<template>
	<div>
		<div class="cancel" style="text-align: center;">
			<div style="padding-top: 22px;">{{title}}</div>
			<div style="padding: 23px 0;">{{content}}</div>
			<div class="between">
				<div class="cancelBtn" @click="closeCancel(0)">取消</div>
				<div class="blue determineBtn" @click="closeCancel(1)">确定</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		data(){
			return{
				
			}
		},
		props:{
			title:{
				type:String,
				default:"提示"
			},
			content:{
				type:String,
				default:"提示正文"
			}
		},
		methods:{
			closeCancel(state){
				this.$emit("closeCancel",state)
			}
		}
	}
</script>

<style>
</style>
