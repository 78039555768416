<template>
	<div>
		<van-form @submit="onSubmit">
			<div class="adressForm">
				<van-cell-group>
					<van-field clearable v-model="adressDetail.contactName" name="contactName" label="name" placeholder="Please enter your name" 
					:rules="[{ required: true, message: 'Please enter your name' }]" />
					
					
					<van-field clearable v-model="adressDetail.telephone" type="number" name="telephone" label="phone" placeholder="Please enter your phone number" 
					:rules="[{ pattern, message: 'Please enter your phone number' }]" />
		
					<van-field readonly clickable name="region" :value="adressDetail.region" label="region" right-icon="arrow" placeholder="Please select your region"
					 :rules="[{ required: true, message: 'Please select your region' }]" @click="showArea = true" />
					 <!-- 选择省市区 -->
					<van-popup v-model="showArea" position="bottom">
						<van-area :area-list="areaList" @confirm="onConfirm" @cancel="showArea = false" />
					</van-popup>
		
					<van-field clearable v-model="adressDetail.address" name="address" label="address" placeholder="Please enter your detailed address" 
					:rules="[{ required: true, message: 'Please enter your detailed address' }]" />
		
				</van-cell-group>
		
			</div>
		
			<div class="width90" style="margin: auto;margin-top: 80px;">
				<van-button :disabled="loadingBtn" class="width90" round block :color="colorBtn" native-type="submit">submit</van-button>
			</div>
		</van-form>
	</div>
</template>

<script>
	import BaseView from "@/views/BaseView.vue"
	import { areaList } from '@vant/area-data';
	import {Toast} from 'vant';
	export default {
		extends: BaseView,
		data() {
			return {
				title: "add address",//添加地址
				pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
				showArea:false,
				name: "",
				phone: "",
				areaInfo: "",
				province:"",
				city:"",
				area:"",
				address: "",
				areaList: areaList,
				submitLoading:false,
				adCode:"",
				adressId:0,
				adressDetail:{
					defaults:0
				}
			}
		},
		created() {
			this.adressId=this.$route.query.addressId
			if(this.adressId){
				this.getAdressDetail()
			}
		},
		methods: {
			//查询地址详情
			getAdressDetail(){
				this.$axios.get("member/address/infoAddress",{
					params:{addressId:this.adressId}
				}).then(res=>{
					console.log("地址详情",res)
					this.adressDetail=res.data
				})
			},
			onConfirm(e){
				console.log("地址",e)
				if(e[0].name==e[1].name){
					this.adressDetail.region=e[1].name+e[2].name
				}else{
					this.adressDetail.region=e[0].name+e[1].name+e[2].name
				}
				
				this.adressDetail.adCode=e[0].code+e[1].code+e[2].code
				this.province=e[0].name
				this.city=e[1].name
				this.area=e[2].name
				this.showArea=false
				
				
			},
			//提交
			onSubmit(value){
				this.loadingBtn=true
				value.defaults=this.adressDetail.defaults
				value.region=this.adressDetail.region
				value.adCode=this.adressDetail.adCode
				if(this.adressId){
					value.id=this.adressId
				}
				console.log("提交", value)
				this.$axios.post("member/address/saveAddress",value).then(res=>{
					console.log("新增地址",res)
					if(res.code==1){
						Toast("success")
						setTimeout(()=>{
							this.loadingBtn=false
							this.$router.go(-1)
						},1000)
					}
				})
			}
		}
	}
</script>

<style>
	.adressForm {
		width: 90vw;
		background: #FFFFFF;
		border-radius: 10px;
		margin: auto;
		margin-top: 15px;
	}
	
	.adressForm .van-cell-group {
		border-radius: 10px;
	}
	
	.adressForm .van-cell {
		border-radius: 10px;
	}
</style>
