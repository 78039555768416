<template>
	<div class="agreement" v-html="agreement.details">
		
	</div>
</template>

<script>
	import BaseView from "@/views/BaseView.vue"
	export default {
		extends: BaseView,
		data() {
			return {
				title:"协议",
				state:0,
				agreement:{}
			}
		},
		created() {
			this.title=this.$route.query.title
			this.state=this.$route.query.state
			this.getAgreement()
		},
		methods: {
			getAgreement(){
				this.$axios.get("text/base/textInfo",{
					params:{serverType:this.state}
				}).then(res=>{
					console.log("协议",res)
					this.agreement=res.data
				})
			},
		}
	}
</script>

<style>
	.agreement{
		width: 90vw;
		padding: 0 5vw;
	}
	.agreement img{
		width: 90vw;
		/* padding: 0 5vw; */
	}
	.agreement video{
		width: 90vw;
		max-height: 300px;
		/* padding: 0 5vw; */
	}
</style>
