<template>
	<div>
		<div style="width: 82vw;padding: 10px 4vw;">流水号：{{serialNumber}}</div>
		<div style="width: 82vw;padding: 10px 4vw;">
			<van-uploader v-model="fileList" :after-read="afterRead" :before-delete="deleteImg" style="margin-top: 12px;" :max-count="1" />
		</div>
		
		<div style="margin: 16px;" @click="onSubmit">
			<van-button :disabled="loadingBtn" round block type="info" :color="colorBtn">提交</van-button>
		</div>
	</div>
</template>

<script>
	import BaseView from "@/views/BaseView.vue"
	import {
		Toast
	} from 'vant';
	export default {
		extends: BaseView,
		data() {
			return {
				title: "上传图片",
				backcolor: "#ffffff",
				loadingBtn:false,
				fileList: [],
				imgList: [],
				serialNumber:""
			}
		},
		created() {
			
			if(this.$route.query.serialNumber){
				this.serialNumber =this.$route.query.serialNumber
			}else if(this.getUrlParam("serialNumberA")){
				this.serialNumber = this.getUrlParam("serialNumber")
				console.log("serialNumberB",this.serialNumber)
			}
			
			
		},
		methods: {
			
			onSubmit() {
				if(this.imgList.length<=0){
					Toast("请上传图片")
					return;
				}
				var param={
					imgJson:this.imgList.toString(),
					serialNumber:this.serialNumber
				}
				console.log("param",param)
				this.$axios.post("/order/base/orderImg",param).then(res=>{
					Toast(res.msg)
				})
				
			},
			//图片上传
			afterRead(file) {
				// 此时可以自行将文件上传至服务器
				console.log(file);
				file.status = 'uploading'
				file.message = "上传中"
				var datas = new FormData()
				datas.append('file', file.file)
				this.$axios.post("img/base/imgUpLoad", datas).then(res => {
					console.log("文件上传", res)
					if (res.code == 1) {
						file.status = 'done'
						this.imgList.push(res.data.url)
						// this.imgIdList.push(res.data.id)
						console.log("图片list",this.imgList)
					} else {
						file.status = 'failed'
						file.message = "上传失败"
					}
				}).catch(err => {
					// console.log("错误",err)
					file.status = 'failed'
					file.message = "上传失败"
				})
			},
			//删除图片
			deleteImg(file, detail) {
				console.log("删除", file)
				console.log("删除2", detail)
				this.imgList.splice(detail.index, 1)
				this.fileList.splice(detail.index, 1)
				// this.imgIdList.splice(detail.index, 1)
			}
		}
	}
</script>

<style>
</style>