import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import store from './store'
import Vant from 'vant';
import Axios from 'axios'
// import apiConfig from '@common/api.config.js'
import 'vant/lib/index.css';
import wx from "weixin-js-sdk";

import qs from 'qs'

import configVo from './views/vo/configVo'
Vue.prototype.configVo=configVo

//复制
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)


import {
  Button, Image, Toast, NavBar, Lazyload, Dialog, Form, Icon, Cell, CellGroup, PullRefresh, Checkbox, Search,
  Sticky, Field, Empty, Swipe, SwipeItem, Tab, Tabs, Tabbar, TabbarItem, Popup, Loading, List, Card, SwipeCell,
  ActionSheet, Uploader, Overlay, CountDown, GoodsAction, GoodsActionIcon, GoodsActionButton, Stepper, SubmitBar,
  Radio, RadioGroup, Picker
} from 'vant'

Vue.use(Button).use(Image).use(Toast).use(NavBar).use(Lazyload).use(Dialog).use(Sticky).use(Popup).use(Checkbox);
Vue.use(Form).use(Field).use(Icon).use(Cell).use(CellGroup).use(Empty).use(Swipe).use(SwipeItem).use(PullRefresh);
Vue.use(Tab).use(Tabs).use(Tabbar).use(TabbarItem).use(Loading).use(List).use(Overlay).use(ActionSheet).use(Uploader);
Vue.use(Card).use(CountDown).use(GoodsAction).use(GoodsActionButton).use(GoodsActionIcon).use(Stepper).use(SubmitBar);
Vue.use(SwipeCell).use(Radio).use(RadioGroup).use(Search).use(Picker);

Vue.use(Vant);

Vue.config.productionTip = false

Vue.prototype.$axios = Axios;
Vue.prototype.$wx = wx;
// Axios.defaults.baseURL = apiConfig.baseUrl;
const isPro = Object.is(process.env.NODE_ENV, "production"); // 判断开发环境，true为生产环境即线上，false为开发环境
isPro ? Axios.defaults.baseURL = "/apiCheck" : Axios.defaults.baseURL = "/apiCheck/apiCheck";//调试

// Axios.defaults.baseURL = "http://47.92.154.247:8081/apiCheck";
// Vue.prototype.$urls = "https://47.92.154.247:8081/"; //测试url接口
// Vue.prototype.$urls = "https://192.168.1.254:8081/apiCheck"; //线上url接口
Vue.prototype.$urls = "https://board.ebaoku.cugi.net"; //线上url接口

Toast.setDefaultOptions({ duration: 1500, forbidClick: true });

// http 请求拦截器
Axios.interceptors.request.use((config) => {
	var token = localStorage.getItem('token');
	if (token) {
		// config.headers['Authorization'] ="Bearer "+token;
		config.headers['Authorization'] =token;
	}
	config.headers['contentType'] = 'application/json;charset=UTF-8';
	console.log("config",config)
	if (Object.prototype.toString.call(config.data) != '[object FormData]') {
	    // 请求拦截器处理
		config.data = qs.stringify(config.data);
	}
	
	// if(config.method == 'post'){//query传参
	// 	config.params = config.data;
	// }
	
	return config
}, (err) => {
	return Promise.reject(err);
})

// http 响应拦截器
Axios.interceptors.response.use((response) => {
	var token = response.headers.authorization;
	response.headers['contentType'] = 'application/json;charset=UTF-8';
	if (token) {
		localStorage.setItem('token', token);
	}
	// var code = response.data.code;
	if(response.data.code!=1){
		Toast(response.data.msg)
	}
	return response.data
}, error => {
	
	if (error.response) {
		Toast.clear();
		console.log("错误信息",error.response)
		switch (error.response.status) {
			case 401:
				Dialog.alert({
					title: 'prompt',
					message: 'login information has expired, please log in again',//登录信息已失效，请重新登录
				}).then(() => {
					console.log("登录",router.currentRoute.fullPath)
					router.replace({
						path: '/login',
						// query: {
						// 	redirect: router.currentRoute.fullPath
						// }
					})
					
					// let redirect_uri = encodeURIComponent("https://board.ebaoku.cugi.net/#/login")
					
					// window.location.href =
					// 	"https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxc0cf90cee4de1756&redirect_uri=" + redirect_uri +
					// 	"&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect"
					
					// router.push({
					// 	path: '/login',
					// 	query: {
					// 		redirect: router.currentRoute.fullPath
					// 	}
					// })
				});
				break;
			case 2001:
				Dialog.alert({
					title: 'prompt',
					message: 'please log in again',
				}).then(() => {
					console.log("登录",router.currentRoute.fullPath)
					
					let redirect_uri = encodeURIComponent("https://board.ebaoku.cugi.net/#/login")
					
					window.location.href =
						"https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxc0cf90cee4de1756&redirect_uri=" + redirect_uri +
						"&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect"
					
				});
				break;
			case 422:
				Toast(error.response.statusText)
				break;
			default:
				Toast(error.response.statusText);
				break;
		}
	}

	return Promise.reject(error)
})

new Vue({
	router,
	// store,
	render: h => h(App)
}).$mount('#app')
