<template>
	<div>
		<div class="quality">
			<div>
				<span class="text-20">{{testNumber}}</span>
				<span class="text-14">number</span>
			</div>
			<div class="text-14">
				<!-- 免费质检（证书型） -->
				Free quality inspection (certificate type)
			</div>
		</div>

		<!-- 使用记录 -->
		<div>
			<div style="padding: 8px 5vw;">Usage Record</div>
			<div>
				<div v-if="showSkeleton">
					<div class="width82 backgroundWhite radius5 margin-bottom-10" v-for="i in 5" :key="i">
						<van-skeleton title :row="2" />
					</div>

				</div>
				<van-empty v-if="!showSkeleton&&integralList.length<=0" class="custom-image" :image="emptyImg"
					description="You haven't had a usage record yet~" />
				<div v-if="!showSkeleton&&integralList.length>0">
					<van-pull-refresh v-model="refreshing" @refresh="onRefresh">
						<van-list v-model="loading" :finished="finished" finished-text="There's no more" @load="onLoad">
							<div class="width82 backgroundWhite radius5 margin-bottom-10"
								v-for="(item,index) in integralList" :key="index">
								<div class="between text-14">
									<div>number of uses</div>
									<div class="blod">{{item.profitNumber}}</div>
								</div>
								<div class="gray text-12 padding-top-5">
									order:{{item.itemId}}
								</div>
								<div class="gray text-12 padding-top-5">
									time:{{item.createDate}}
								</div>
							</div>
						</van-list>
					</van-pull-refresh>
				</div>

			</div>
		</div>
	</div>
</template>

<script>
	import BaseView from "@/views/BaseView.vue"
	export default {
		extends: BaseView,
		data() {
			return {
				title: "quality",//免费质检
				showSkeleton: true,
				refreshing:false,
				loading:false,
				finished:false,
				testNumber: 0,
				param: {
					page: 1,
					limit:10
				},
				integralList: [],
				total: 0
			}
		},
		created() {
			this.getTestNumber()
			this.getTestNumberRecord()
			setTimeout(() => {

			}, 1000)
		},
		methods: {
			//用户免费质检次数
			getTestNumber() {
				this.$axios.get("/member/testNumber/testNumber").then(res => {
					console.log("用户免费质检次数", res)
					this.testNumber = res.data
				})
			},
			//质检使用记录
			getTestNumberRecord() {
				this.$axios.get("/member/testNumber/checkUseNumberRecord", {
					params: this.param
				}).then(res => {
					console.log("质检记录", res)
					this.showSkeleton = false
					this.loading=false
					this.refreshing = false
					if (this.param.page == 1) {
						this.integralList = res.data
					} else {
						this.integralList = this.integralList.concat(res.data)
					}
					this.total = res.count
					if (this.total <= this.integralList.length) {
						this.finished=true
					}
				})
			},
			onRefresh(){
				this.finished=false
				this.refreshing = true
				this.param.page=1
				// this.certificateList=[]
				// this.showSkeleton=true
				this.getTestNumberRecord()
			},
			onLoad(){
				if (this.total <= this.integralList.length) {
					this.finished=true
					return;
				}
				this.param.page++
				this.getTestNumberRecord()
			}
		}
	}
</script>

<style>
	.quality {
		width: 100vw;
		height: 120px;
		background: url(../../assets/images/quality.png);
		background-size: 100% 100%;
		text-align: center;
		color: #FFFFFF;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
</style>
