<template>
	<div>
		<!-- 头部统计 -->
		<div class="integral">
			<div style="width: 100%;">
				<div>
					<div class="text-22 blod">{{integralInfo.integral}}</div>
					<div class="text-16">available</div>
					<!-- 可用积分 -->
				</div>
				<div style="display: flex;flex-direction: row;justify-content: space-around;padding-top: 20px;">
					<div>
						<div class="text-18">{{integralInfo.integralAll}}</div>
						<div class="text-12">cumulative</div>
						<!-- 累计积分 -->
					</div>
					<div style="border-left: #FFFFFF 1px solid;height: 40px;"></div>
					<div>
						<div class="text-18">{{integralInfo.integralUsed}}</div>
						<div class="text-12">used</div>
						<!-- 已使用积分 -->
					</div>
				</div>
			</div>

		</div>

		<!-- 积分明细 -->
		<div>
			<div style="padding: 8px 5vw;">Points Details</div>
			<div>
				<div v-if="showSkeleton">
					<div class="width82 backgroundWhite radius5 margin-bottom-10" v-for="i in 5" :key="i">
						<van-skeleton title :row="3" />
					</div>

				</div>

				<van-empty v-if="!showSkeleton&&integralList.length>0<=0" class="custom-image" :image="emptyImg" description="You don't have the points details yet~" />
				<div v-if="!showSkeleton&&integralList.length>0">
					<van-pull-refresh v-model="refreshing" @refresh="onRefresh">
						<van-list v-model="loading" :finished="finished" finished-text="There's no more" @load="onLoad">
							<div class="width82 backgroundWhite radius5 margin-bottom-10" v-for="(item,index) in integralList" :key="index">
								<div class="between text-14">
									<div>{{item.title}}</div>
									<div class="blod">{{item.profitMoney}}</div>
								</div>
								<div class="gray text-12 padding-top-5" v-if="item.order">
									order:{{item.itemCode}}
								</div>
								<div class="gray text-12 padding-top-5" v-if="item.remarks">
									remarks:{{item.remarks}}
								</div>
								<div class="gray text-12 padding-top-5">
									time:{{item.createDate}}
								</div>
							</div>
						</van-list>
					</van-pull-refresh>
				</div>

			</div>
		</div>
	</div>
</template>

<script>
	import BaseView from "@/views/BaseView.vue"
	export default {
		extends: BaseView,
		data() {
			return {
				title: "integral",//积分
				showSkeleton: true,
				refreshing:false,
				finished:false,
				loading:false,
				param: {
					page: 1,
					limit:10,
					profitType:2
				},
				total:0,
				integralInfo: {},
				integralList: []
			}
		},
		created() {
			this.getIntegralList()
			this.getIntegral()
		},
		methods: {
			//积分明细
			getIntegralList() {
				this.$axios.get("member/integral/profitIntegralList", {
					params: this.param
				}).then(res => {
					console.log("积分明细", res)
					this.showSkeleton = false
					this.refreshing = false
					this.loading = false
					if(res.code==1){
						
						if(this.param.page==1){
							this.integralList=res.data
						}else{
							this.integralList=this.integralList.concat(res.data)
						}
						this.total=res.count
						if(this.integralList.length==this.total){
							this.finished=true
						}
					}
				})
			},
			//积分统计
			getIntegral() {
				this.$axios.get("member/integral/integral").then(res => {
					console.log("积分统计", res)
					if (res.code == 1) {
						this.integralInfo = res.data
					}
				})
			},
			//刷新
			onRefresh(){
				this.refreshing = true
				this.finished = false
				this.param.page=1
				// this.integralList=[]
				this.getIntegralList()
			},
			//加载
			onLoad(){
				if(this.integralList.length>=this.total){
					this.finished=true
					return;
				}
				this.param.page++
				this.getIntegralList()
			}
		}
	}
</script>

<style>
	.integral {
		width: 90vw;
		/* height: 42.7vw; */
		height: 160px;
		background: url(../../assets/images/integral.png);
		background-size: 100% 100%;
		text-align: center;
		color: #FFFFFF;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0 5vw;
	}
</style>
