<template>
	<div>
		<div class="margin-top-10">
			<div v-if="showSkeleton">
				<div class="width82 backgroundWhite radius5 margin-bottom-10" v-for="i in 5" :key="i">
					<van-skeleton title :row="5" />
				</div>

			</div>
			<van-empty v-if="!showSkeleton&&monthlyStatementList.length<=0" class="custom-image" :image="emptyImg"
				description="You don't have a bill yet" />
			<div v-if="!showSkeleton&&monthlyStatementList.length>0">
				<van-pull-refresh v-model="refreshing" @refresh="onRefresh">
					<van-list v-model="loading" :finished="finished" finished-text="There's no more" @load="onLoad">
						<div class="width82 backgroundWhite radius5 margin-bottom-10"
							v-for="(item,index) in monthlyStatementList" :key="index" @click="toDetail(item)">
							<div class="between text-14">
								<div>{{item.meltMonth}}</div>
								<div class="blod">￥{{item.meltMoney}}</div>
							</div>
							<div class="gray text-12 padding-top-5">
								order number:{{item.orderNumber}}
							</div>
							<div class="gray text-12 padding-top-5">
								write off status:{{item.state==1?"reimbursing":item.state==2?"uncollected accounts":"outstanding"}}
								<!-- {{item.state==1?"已销账":item.state==2?"未销账":"未出账"}} -->
							</div>
							<div class="gray text-12 padding-top-5">
								site name:{{item.siteName}}
							</div>
							
							<div class="gray text-12 padding-top-5">
								payment time:{{item.billingDate?item.billingDate:"outstanding"}}
							</div>
							<div class="gray text-12 padding-top-5">
								write off time:{{item.meltDate?item.meltDate:"uncollected accounts"}}
							</div>
							<div class="gray text-12 padding-top-5">
								next billing date:{{item.nextBillingDate}}
							</div>
						</div>
					</van-list>
				</van-pull-refresh>
			</div>

		</div>
	</div>
</template>

<script>
	import BaseView from "@/views/BaseView.vue"
	export default {
		extends: BaseView,
		data() {
			return {
				title: "write off records",//销账记录
				showSkeleton: true,
				refreshing: false,
				loading: false,
				finished: false,
				param:{
					page:1,
					limit:10
				},
				monthlyStatementList: [],
				total:0
			}
		},
		created() {
			
			this.getList()
		},
		methods: {
			//查询月结账单
			getList(){
				this.$axios.get("/bill/month/billMonthListPage",{
					params:this.param
				}).then(res=>{
					// console.log("月结账单",res)
					this.showSkeleton = false
					this.refreshing=false
					this.loading=false
					// console.log("********",this.param.page)
					if(this.param.page==1){
						this.monthlyStatementList=res.data
					}else{
						this.monthlyStatementList=this.monthlyStatementList.concat(res.data)
					}
					this.total=Number(res.count) 
					if(this.total<=this.monthlyStatementList.length){
						this.finished=true
					}
				})
			},
			toDetail(item){
				this.$router.push({
					path:"/monthlyStatement",
					query:{
						orderId:item.id
					}
				})
			},
			onRefresh() {
				this.param.page=1
				this.refreshing=true
				this.finished=false
				// this.monthlyStatementList=[]
				// this.showSkeleton = true
				this.getList()
			},
			onLoad() {
				if(this.total<=this.monthlyStatementList.length){
					this.finished=true
					return;
				}
				this.param.page++
				this.getList()
			}
		}
	}
</script>

<style>
</style>
