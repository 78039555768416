<template>
	<!-- 未用 -->
	<div>
		<div class="margin-top-10">
			<div v-if="showSkeleton">
				<div class="width82 backgroundWhite radius5 margin-bottom-10" v-for="i in 5" :key="i">
					<van-skeleton title :row="6" />
				</div>
				
			</div>
			<van-empty v-if="!showSkeleton&&monthlyStatementList.length<=0" class="custom-image" :image="emptyImg" description="You have no record of cancellation yet" />
			<div v-if="!showSkeleton&&monthlyStatementList.length>0">
				<van-pull-refresh v-model="refreshing" @refresh="onRefresh">
					<van-list v-model="loading" :finished="finished" finished-text="There's no more" @load="onLoad">
						<div class="width82 backgroundWhite radius5 margin-bottom-10" v-for="(item,index) in monthlyStatementList" :key="index">
							<div class="text-14">
								<div>write-off details</div>
								<!-- <div class="blod">{{item.price}}</div> -->
							</div>
							<div class="gray text-12 padding-top-5">
								write-off number:{{item.meltCode}}
							</div>
							<div class="gray text-12 padding-top-5">
								site name:￥{{item.siteName}}
							</div>
							<div class="gray text-12 padding-top-5">
								write-off amount:{{item.bookingMoney}}
							</div>
							<div class="gray text-12 padding-top-5">
								write-off time:{{item.meltDate}}
							</div>
						</div>
					</van-list>
				</van-pull-refresh>
				
			</div>
			
		</div>
	</div>
</template>

<script>
	import BaseView from "@/views/BaseView.vue"
	export default {
		extends: BaseView,
		data() {
			return {
				title: "write-off records",//销账记录
				showSkeleton:true,
				param:{
					page:1,
					limit:10
				},
				monthlyStatementList:[]
			}
		},
		created() {
			this.param.siteId=this.$route.query.siteId
			this.getList()
		},
		methods: {
			getList(){
				
				this.$axios.get("/bill/booking/billBookingListPage",{
					params:this.param
				}).then(res=>{
					console.log("记账记录",res)
					
					this.refreshing= false
					this.loading= false
					this.showSkeleton=false
					if(this.param.page==1){
						this.monthlyStatementList=res.data
					}else{
						this.monthlyStatementList=this.monthlyStatementList.concat(res.data)
					}
					this.total=res.count
					if(this.total<=this.monthlyStatementList.length){
						this.finished=true
					}
					
				})
			},
			onRefresh(){
				this.param.page=1
				this.finished=false
				this.refreshing=true
				// this.bookkeeping=[]
				// this.showSkeleton=true
				this.getList()
			},
			onLoad(){
				if(this.total<=this.monthlyStatementList.length){
					this.finished=true
					return;
				}
				this.param.page++
				this.getList()
			},
		}
	}
</script>

<style>
</style>
