<template>
	<div>
		<van-tabs v-model="state" swipeable color="#0080FD" sticky @change="changeTabs">
			<van-tab v-for="(tab,key) in tabList" :key="key" :title="tab.title">
				<!-- 骨架屏 -->
				<div v-if="showSkeleton">
					<div class="width82 backgroundWhite radius5 margin-top-10" v-for="i in 6" :key="i">
						<van-skeleton :row="2" />
						<div class="borderBottom" style="margin: 10px 0;"></div>
						<van-skeleton :row="4" />
						<div class="borderBottom" style="margin: 10px 0;"></div>
						<van-skeleton :row="1" />
					</div>

				</div>
				<!-- 列表 -->
				<van-empty v-if="!showSkeleton&&orderList.length<=0" class="custom-image" :image="emptyImg" description="You haven't sent an inspection order yet~" />
				<div v-if="!showSkeleton&&orderList.length>0">
					<van-pull-refresh v-model="refreshing" @refresh="onRefresh">
						<van-list v-model="loading" :finished="finished" finished-text="There's no more" @load="onLoad">
							<div class="width82 backgroundWhite margin-top-10 radius10" v-for="(item,index) in orderList" :key="index" @click="toDetail(item)">
								<div class="borderBottom padding-bottom-10 text-12 text999">
									<div class="betweenCenter">
										<div>order:{{item.orderCode}}</div>
										<!-- 加急 -->
										<div class="text-14 orange" v-if="item.urgent==1">Urgent</div>
									</div>
									<div>create time:{{item.createDate}}</div>
								</div>
								<div class="borderBottom paddingtb10 text-14">
									<div class="blue" v-if="item.expectedSamplingDate">estimated sampling time:{{item.expectedSamplingDate}}</div>
									<div style="padding: 5px 0;">site name:{{item.siteName}}</div>
									<!-- <div>送检材质:{{item.textureOfMaterial?item.textureOfMaterial:"无"}}</div> -->
									<div style="padding: 5px 0;">sample number:{{item.sampleNumber}}</div>
									<div v-if="item.state!=1&&item.state!=27"><!-- item.state!=1&&item.state!=6 -->
										settlement method:{{item.settlementType==2?'pay':item.settlementType==3?'monthly':item.settlementType==4?'pending':'other'}}
										<!-- {{item.settlementType==2?'现付':item.settlementType==3?'月结':item.settlementType==4?'挂账':'其他'}} -->
									</div><!-- 1 现结  2 月结 3 记账 -->
								</div>
								<div class="padding-top-10 betweenCenter text-12">
									<div>
										<!-- 合计费用 -->
										<span style="color: #515151;">amount:</span>
										<span class="orange">￥{{item.totalCost}}</span>
									</div>
									<div class="between">
										<div class="strokeBtn margin-right-10" v-if="item.state==1" @click.stop="clickCancel(item)">cancel</div>
										<div @click="toDetail(item)" :class="[(item.state==16||item.state==18||item.state==21)?'strokeBtn':'solidBtn']">view orders</div>
										<div class="solidBtn margin-left-10" v-if="item.state==16" @click.stop="pay(item)">sampling payment</div>
										<div class="solidBtn margin-left-10" v-if="item.state==21" @click.stop="toOpinion">opinion</div>
									</div>
								</div>
							</div>
						</van-list>
					</van-pull-refresh>
				</div>
			</van-tab>
		</van-tabs>

		<!-- 取消订单 -->
		<van-popup v-model="showTips" round>
			<promptBox title="prompt" content="Confirm to cancel this inspection appointment？" @closeCancel="closeCancel"></promptBox>
		</van-popup>
		
		<!-- 支付方式 -->
		<van-action-sheet v-model="showPayment" title="pay type" >
			<payment :price="payPrice" :orderId="orderId" :orderType="4" @paySuccess='paySuccess'></payment>
		</van-action-sheet>
	</div>
</template>

<script>
	import BaseView from "@/views/BaseView.vue"
	import promptBox from "@/components/promptBox.vue"
	import payment from "@/components/payment.vue"
	import { Dialog } from 'vant';
	import { Toast } from 'vant';
	export default {
		extends: BaseView,
		components: {
			promptBox,
			payment
		},
		data() {
			return {
				title: "inspection order",//送检订单
				showPayment:false,
				state: 0,
				refreshing:false,
				finished:false,
				loading:false,
				showSkeleton: true,
				showTips: false,
				emptyImg: require("@/assets/images/empty.png"),
				// tabList: ["待确认", "检测中", "待付款","待取样", "已完成", "已取消"],
				tabList:[
					{
						title: "toBeConfirmed",
						// name: "待确认",
						status:1
					},
					{
						title: "testing",
						// name: "检测中",
						status:2
					},
					{
						title: "pendingPayment",
						// title:"待付款",
						status:16
					},
					{
						title: "toBeSampled",
						// title:"待取样",
						status:18
					},
					{
						title: "completed",
						// title:"已完成",
						status:21
					},
					{
						title: "canceled",
						// title:"已取消",
						status:27
					},
				],
				param:{
					page:1,
					limit:10
				},
				total:0,
				orderList: [],
				payPrice:0,//支付金额
				orderId:0,//订单id
			}
		},
		created() {
			this.state = parseInt(this.$route.query.state)
			this.param.state=this.tabList[this.state].status
			this.getOrderList()
		},
		methods: {
			//查询订单列表
			getOrderList(){
				console.log("查询列表")
				
				this.$axios.get("/order/base/orderList",{
					params:this.param
				}).then(res=>{
					console.log("订单列表",res)
					this.showSkeleton = false
					this.refreshing = false
					this.loading=false
					if(res.code==1){
						if(this.param.page==1){
							this.orderList=res.data
						}else{
							this.orderList=this.orderList.concat(res.data)
						}
						this.total=res.count
						if(this.total<=this.orderList.length){
							this.finished=true
						}
					}
				})
			},
			changeTabs(e) {
				console.log("切管tabs", e)
				this.state = e
				this.param.state=this.tabList[this.state].status
				this.param.page=1
				this.finished=false
				this.showSkeleton = true
				this.orderList=[]
				this.getOrderList()
			},
			//取消订单
			clickCancel(item) {
				
				
				// this.showTips = true
				Dialog.confirm({
				  title: 'prompt',
				  message: 'Confirm to cancel this inspection appointment?',
				  confirmButtonColor:this.defaultColor,
				  confirmButtonText:"determine",//确定
				  cancelButtonText:"cancellation",//取消
				})
				  .then(() => {
				    // on confirm
					console.log("确定取消",item)
					this.$axios.post("/order/base/cancelOrder",{
						orderId:item.id
					}).then(res=>{
						console.log("确定取消返回",res)
						Toast(res.msg)
						this.onRefresh()
					})
				  })
				  .catch(() => {
				    // on cancel
				  });
			},
			closeCancel(value) {
				this.showTips = false
				console.log("关闭弹框", value)
			},
			//取样付款
			pay(item){
				console.log("取样付款",item)
				this.orderId=item.id
				this.payPrice=item.totalCost
				this.showPayment=true
			},
			//查看订单
			toDetail(item) {
				this.$router.push({
					path: "/submissionOrderDetail",
					query: {
						state: this.state,
						orderId:item.id
					}
				})
			},
			//意见反馈
			toOpinion() {
				this.$router.push({
					path: "/opinion"
				})
			},
			onRefresh(){
				this.refreshing = true
				this.finished = false
				// this.showSkeleton=true
				this.param.page=1
				// this.orderList=[]
				this.getOrderList()
			},
			onLoad(){
				if(this.total<=this.orderList.length){
					this.finished=true
					return;
				}
				this.param.page++
				this.getOrderList()
			},
			paySuccess(){
				this.showPayment=false
				this.onRefresh()
			}
		}
	}
</script>

<style>
</style>
