<template>
	<div>
		<div v-if="loadingBig">
			<loadingBig></loadingBig>
		</div>
		
		<!-- 内容 -->
		<div class="width90 margin-top-10" v-else>
			
			<van-image fit="cover" class="contenImg" :src="certificateInfo.certificateImgUrl" @click="imagePreview([certificateInfo.certificateImgUrl])" ></van-image>
			<div>
				<div class="between borderBottom paddingtb10 text-14">
					<div class="gray">result</div>
					<!-- 鉴定结果 -->
					<div class="wrapDiv">{{certificateInfo.checkResult}}</div>
				</div>
				
				<div class="between borderBottom paddingtb10 text-14">
					<div class="gray">certificate code</div>
					<!-- 证书编号 -->
					<div class="wrapDiv">{{certificateInfo.certificateNumber}}</div>
				</div>
				
				<div class="between borderBottom paddingtb10 text-14">
					<div class="gray">total mass </div>
					<!--总质量  -->
					<div class="wrapDiv">{{certificateInfo.totalMass}}</div>
				</div>
				
				<div class="between borderBottom paddingtb10 text-14">
					<div class="gray">color</div>
					<!-- 颜色 -->
					<div class="wrapDiv">{{certificateInfo.color}}</div>
				</div>
				
				<div class="between borderBottom paddingtb10 text-14">
					<div class="gray">Cut</div>
					<!-- 样式 -->
					<div class="wrapDiv">{{certificateInfo.style}}</div>
				</div>
				
				<div class="between borderBottom paddingtb10 text-14">
					<div class="gray">shape</div>
					<!-- 形状 -->
					<div class="wrapDiv">{{certificateInfo.shape}}</div>
				</div>
				
				<!-- <div class="between borderBottom paddingtb10 text-14">
					<div class="gray">enlarge inspect</div>
					放大检查
					<div class="wrapDiv">{{certificateInfo.enlargeCheck}}</div>
				</div>
					
				<div class="between borderBottom paddingtb10 text-14">
					<div class="gray">UV visible spectrum</div>
					紫外可见光谱
					<div class="wrapDiv">{{certificateInfo.ultravioletVisibleSpectrum}}</div>
				</div>
				
				<div class="between borderBottom paddingtb10 text-14">
					<div class="gray">Infrared detection description</div>
					红外检测描述 
					<div class="wrapDiv">{{certificateInfo.infraredDescription}}</div>
				</div>
				
				<div class="between borderBottom paddingtb10 text-14" v-if="certificateInfo.certificateType==1">
					<div class="gray">refractive</div>
					折射率
					<div class="wrapDiv">{{certificateInfo.refractive}}</div>
				</div> -->
				
				<div class="between borderBottom paddingtb10 text-14">
					<div class="gray">object</div>
					<!-- 密度--对象 -->
					<div class="wrapDiv">{{certificateInfo.density}}</div>
				</div>
				
				<!-- <div class="between borderBottom paddingtb10 text-14">
					<div class="gray">stone</div>
					是否测配石数
					<div class="wrapDiv">{{certificateInfo.checkStoneNumber==1?"是":"否"}}</div>
				</div> -->	
				
				<!-- 翡翠 -->
				<!-- <div  v-if="state==0">
					
				</div> -->
				
				<!-- 黄金 -->
				<!-- <div v-if="state==1">
					<div class="between borderBottom paddingtb10 text-14">
						<div class="gray">印记</div>
						<div>显示印记</div>
					</div>
				</div> -->
				<div class="between borderBottom paddingtb10 text-14">
					<div class="gray">origin</div>
					<!-- 其他--产地 -->
					<div class="wrapDiv">{{certificateInfo.other}}</div>
				</div>
				
				<div class="between borderBottom paddingtb10 text-14">
					<!-- 国家 -->
					<div class="gray">from</div>
					<div class="wrapDiv">{{certificateInfo.lightFeatures}}</div>
				</div>
				
				
				
				<div class="between borderBottom paddingtb10 text-14">
					<div class="gray"> remarks</div>
					<!-- 备注 -->
					<div class="wrapDiv">{{certificateInfo.cnote}}</div>
				</div>
				
			</div>
			<!-- <div v-if="certificateInfo.sampleImg">
				<van-image fit="cover" class="contenImg" :src="certificateInfo.sampleImg"></van-image>
			</div> -->
			<div v-if="certificateInfo.sampleImgUrls">
				<div v-for="(img,key) in certificateInfo.sampleImgUrls" :key="key" >
					<van-image fit="cover" class="contenImg" :src="img.cutUrl" @click="imagePreview([img.cutUrl])"></van-image>
				</div>
			</div>
			
			
			
		</div>
	</div>
</template>

<script>
	import BaseView from "@/views/BaseView.vue";
	import loadingBig from "@/components/loading-big.vue"
	import { ImagePreview } from 'vant';
	export default{
		extends:BaseView,
		components:{
			loadingBig
		},
		data(){
			return{
				loadingBig:true,//页面加载
				title:"detail",//详情
				backcolor: "#FFFFFF",
				certificateCode:"",
				state:0,//0翡翠1黄金
				certificateId:0,
				certificateInfo:""
			}
		},
		created(){
			this.certificateId=this.$route.query.certificateId
			this.getCertificateDetail()
		},
		methods:{
			//查询详情
			getCertificateDetail(){
				this.$axios.get("/certificate/base/certificateDetails",{
					params:{certificateId:this.certificateId}
				}).then(res=>{
					
					var certificateInfo=res.data
					// var str=certificateInfo.shape.split(",")
					// certificateInfo.style=str[0]//样式
					// certificateInfo. fashion=str[1]//形状
					console.log("证书详情",certificateInfo)
					setTimeout(()=>{
						this.loadingBig=false
					},500)
					
					this.certificateInfo=res.data
					
				})
			},
			//扫码
			sacn(){
				console.log("扫码")
			},
			//搜索
			search(){
				console.log("搜索",this.certificateCode)
				
			},
			//预览图片
			imagePreview(img){
				console.log("预览图片",img)
				ImagePreview(img);
			}
		}
		
	}
</script>

<style>
	.searchInput{
		border-top-left-radius: 34px;
		border-bottom-left-radius: 34px;
	}
	.input .van-cell{
		line-height: 14px !important;
	}
	.search{
		background: #0080FD;
		color: #FFFFFF;
		width: 50px;
		height: 34px;
		border-top-right-radius: 34px;
		border-bottom-right-radius: 34px;
		text-align: center;
		line-height: 34px;
		font-size: 12px;
	}
	.contenImg{
		width: 90vw;
		/* height: 58.7vw; */
		/* border-radius: 10px; */
		margin-top: 10px;
	}
	.van-image__img{
		border-radius: 0 !important;
	}
</style>
